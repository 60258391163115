import Obfuscate from "react-obfuscate";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { styled, css } from "styled-components";
import theme from "styled-theming";

const linkColor = theme("theme", {
    undefined: "--text-on-neutral-link",
    default: "--text-on-neutral-link",
    white: "--text-on-neutral-link",
    lightgray: "--text-on-neutral-link",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
    coral: "--text-on-coral-primary",
});

const borderColor = theme("theme", {
    undefined: "--text-on-neutral-link-border",
    default: "--text-on-neutral-link-border",
    white: "--text-on-neutral-link-border",
    lightgray: "--text-on-neutral-link-border",
    blue: "--text-on-blue-link-border",
    cyan: "--text-on-cyan-link-border",
    coral: "--text-on-coral-link-border",
});

export const styledLink = css<StyledLinkProps>`
    color: var(${linkColor});
    cursor: pointer;
    text-decoration: none;
    transition:
        border-color 0.2s ease-in-out,
        color 0.2s ease-in-out;

    span {
        transition: border-color 0.2s ease-in-out;
    }

    ${(props) =>
        props.$hoverSpan
            ? css`
                  span {
                      border-bottom: 1px solid var(${borderColor});
                      text-decoration: none;
                  }
              `
            : css`
                  border-bottom: 1px solid var(${borderColor});
              `}

    &.active {
        ${(props) =>
            props.$hoverSpan
                ? css`
                      span {
                          border-bottom: 1px solid var(${borderColor});
                      }
                  `
                : css`
                      border-bottom: 1px solid var(${borderColor});
                  `}
    }

    &:hover {
        ${(props) =>
            props.$hoverSpan
                ? css`
                      span {
                          border-bottom-color: var(${linkColor});
                      }
                  `
                : css`
                      border-bottom-color: var(${linkColor});
                  `}
    }
`;

export const A = styled.a<StyledLinkProps>`
    ${styledLink}
`;

export const NavigationLink = styled(NavHashLink)<StyledLinkProps>`
    ${styledLink}
`;

export const ReduxLink = styled(HashLink)<StyledLinkProps>`
    ${styledLink}
`;

export const ObfuscatedLink = styled(Obfuscate)<StyledLinkProps>`
    ${styledLink}
`;

export interface StyledLinkProps {
    $hoverSpan?: boolean;
    hoverSpan?: boolean;
    className?: string;
}
