import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ReferencePageProps from "./ReferencePageProps";

const ReferencePage = loadable(
    (): Promise<DefaultComponent<ReferencePageProps>> =>
        import(/* webpackChunkName: "ReferencePage" */ "./ReferencePage"),
);
const ReferencePageLoader = (props: ReferencePageProps): ReactElement => (
    <ReferencePage {...props} />
);
export default ReferencePageLoader;
