import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormGuidePageProps from "./FormGuidePageProps";

const FormGuidePage = loadable(
    (): Promise<DefaultComponent<FormGuidePageProps>> =>
        import(/* webpackChunkName: "FormGuidePage" */ "./FormGuidePage"),
);
const FormGuidePageLoader = (props: FormGuidePageProps): ReactElement => (
    <FormGuidePage {...props} />
);
export default FormGuidePageLoader;
