import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import { cookieBotStyle } from "style/cookieBotStyle";
import { GUTTER, gridprops } from "style/grid";
import { MQ } from "style/mediaQueries";

import { colors } from "../colors";
import { FONT, fontFace } from "../fonts";
import { BASE_FONT_SIZE } from "../settings";

export const GlobalStyle = createGlobalStyle`
    ${normalize};
    ${fontFace};

    :root {
        ${colors}
        ${gridprops}
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    :focus-visible {
        outline-offset: 7px;
        outline: 7px solid var(--button-focus);
    }

    html {
        background-color: var(--color-black-l95);
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

    body {
        color: var(--color-black);
        font-family: ${FONT.WHITNEY};
        font-size: ${BASE_FONT_SIZE}px;
        font-feature-settings: "salt" on;

        &.freeze {
            overflow: hidden;
        }

        & > iframe {
            pointer-events: none;
        }

        ${cookieBotStyle};
    }

    body,
    #root {
        min-height: 100vh;
    }

    body.showgrid {
        &::after, &::before{
            z-index: 9999;
            display: block;
            inset: 0;
            height: 100vh;
            position: fixed;
            pointer-events: none;
            content: '';
        }

        &::after {
            background-image: repeating-linear-gradient(to bottom, transparent 0, transparent 7px, rgb(0 0 0 / 10%) 7px, rgb(0 0 0 / 10%) 14px);
        }

        &::before {
            --gutter: ${GUTTER.SM};
            --margin: var(--gutter);
            --colcolor: rgb(0 0 0 / 10%);
            --gapcolorcolor: transparent;
            --col: 4;
            --colwidth: calc((100% - ((var(--col) - 1) * var(--gutter))) / var(--col));

            background-image: repeating-linear-gradient(to right, var(--colcolor), var(--colcolor) var(--colwidth), var(--gapcolorcolor) var(--gutter), var(--gapcolorcolor) calc(var(--colwidth) + var(--gutter)));
            left: var(--margin);
            right: var(--margin);

            ${MQ.FROM_M}{
                --col: 12;
                --colcolor: rgb(0 0 255 / 10%);
                --gutter: ${GUTTER.MD};
            }

            ${MQ.FROM_L}{
                --colcolor: rgb(0 255 255 / 10%);
            }

            ${MQ.FROM_XL}{
                --colcolor: rgb(0 255 0 / 10%);
                --gutter:  ${GUTTER.XL};
            }

            ${MQ.FROM_XXL}{
                --colcolor: rgb(255 0 0 / 10%);
                --margin: calc((100% - 1568px) / 2);
            }
        }
    }
`;
