import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import EmbedProps from "./EmbedProps";

const Embed = loadable(
    (): Promise<DefaultComponent<EmbedProps>> =>
        import(/* webpackChunkName: "Embed" */ "./Embed"),
);
const EmbedLoader = (props: EmbedProps): ReactElement => <Embed {...props} />;

export default EmbedLoader;
