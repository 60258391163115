import { RefObject } from "react";

import useEventListener from "./useEventListener";

type Handler = (event: MouseEvent) => void;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T> | RefObject<T>[],
    handler: Handler,
    mouseEvent: "mousedown" | "mouseup" = "mousedown",
): void {
    useEventListener(mouseEvent, (event) => {
        if (!Array.isArray(ref)) ref = [ref];

        if (
            ref.some(
                (r) => r.current && r.current.contains(event.target as Node), // Do nothing if clicking ref's element or descendent elements
            )
        ) {
            return;
        }

        handler(event);
    });
}

export default useOnClickOutside;
