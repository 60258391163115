import { css } from "styled-components";

import { styledLink } from "components/Link/Link.styled";
import { styledListOL, styledListUL } from "components/List/List.styled";
import { styledTable } from "components/Table/Table.styled";

import { styleBodyM, styleHeadingM } from "./components/Typography";
import { MQ } from "./mediaQueries";

export const cookieBotStyle = css`
    a#CybotCookiebotDialogPoweredbyCybot,
    div#CybotCookiebotDialogPoweredByText {
        display: none;
    }

    .CookieDeclaration {
        ${styleBodyM};

        .CookieDeclarationType {
            border: none;
            padding: 0;
        }

        .CookieDeclarationTypeHeader {
            ${styleHeadingM}
            margin-bottom: 1.75rem;
            margin-top: 2.1875rem;

            ${MQ.FROM_M} {
                margin-bottom: 2.1875rem;
                margin-top: 2.625rem;
            }

            ${MQ.FROM_XL} {
                margin-bottom: 2.625rem;
                margin-top: 3.5rem;
            }
        }

        a {
            ${styledLink}
        }

        table {
            ${styledTable}
        }

        p {
            margin-bottom: 1.75rem;
            margin-top: 1.75rem;
        }

        ${styledListOL}

        ${styledListUL}
    }
`;
