import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NotFoundPageProps from "./NotFoundPageProps";

const NotFoundPage = loadable(
    (): Promise<DefaultComponent<NotFoundPageProps>> =>
        import(/* webpackChunkName: "NotFoundPage" */ "./NotFoundPage"),
);
const NotFoundPageLoader = (props: NotFoundPageProps): ReactElement => (
    <NotFoundPage {...props} />
);
export default NotFoundPageLoader;
