import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SideBySideProps from "./SideBySideProps";

const SideBySide = loadable(
    (): Promise<DefaultComponent<SideBySideProps>> =>
        import(/* webpackChunkName: "SideBySide" */ "./SideBySide"),
);
const SideBySideLoader = (props: SideBySideProps): ReactElement => (
    <SideBySide {...props} />
);
export default SideBySideLoader;
