// Used for storybook, not populated from episerver

import EpiPage from "types/EpiPage";
import RootState from "types/state/RootState";

const defaultPreloadedState: RootState<EpiPage> = {
    pageContent: {},
    app: { loading: false, freeze: false },
    form: { forms: [] },
    footer: {},
    header: { visible: true, searchOverlayVisible: false },
    navigation: {},
    editMode: { isInEditMode: false },
    documentLanguage: "sv",
    googleTagManager: {},
    googleMapsKey: "AIzaSyC_v-CbIBWSWcr7nGBInmfENHzm2zzgAiM",
    cookieConsent: {},
    applicationInsightsKey: "",
    languagePickerPosition: "footer",
    brand: "",
    systemMessage: {},
};

export default defaultPreloadedState;
