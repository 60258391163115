import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SalaryExchangeProps from "./SalaryExchangeProps";

const SalaryExchange = loadable(
    (): Promise<DefaultComponent<SalaryExchangeProps>> =>
        import(/* webpackChunkName: "SalaryExchange" */ "./SalaryExchange"),
);
const SalaryExchangeLoader = (props: SalaryExchangeProps): ReactElement => (
    <SalaryExchange {...props} />
);
export default SalaryExchangeLoader;