import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NewsPageSectionProps from "./NewsPageSectionProps";

const NewsPageSection = loadable(
    (): Promise<DefaultComponent<NewsPageSectionProps>> =>
        import(/* webpackChunkName: "NewsPageSection" */ "./NewsPageSection"),
);
const NewsPageSectionLoader = (props: NewsPageSectionProps): ReactElement => (
    <NewsPageSection {...props} />
);
export default NewsPageSectionLoader;
