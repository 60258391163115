import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ErrorPageProps from "./ErrorPageProps";

const ErrorPage = loadable(
    (): Promise<DefaultComponent<ErrorPageProps>> =>
        import(/* webpackChunkName: "ErrorPage" */ "./ErrorPage"),
);
const ErrorPageLoader = (props: ErrorPageProps): ReactElement => (
    <ErrorPage {...props} />
);
export default ErrorPageLoader;
