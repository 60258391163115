import { css } from "styled-components";

import { MQ } from "./mediaQueries";

export const headerSizes = {
    XL: "7.875rem",
    M: "6.125rem",
    S: "4.375rem",
};

export const headerSizesWithOffset = {
    XL: `calc(${headerSizes.XL} + 1.3125rem)`,
    M: `calc(${headerSizes.M} + 1.3125rem)`,
    S: `calc(${headerSizes.S} + 1.3125rem)`,
};

export const scrollMarginTopPad = css`
    scroll-margin-top: ${headerSizes.S};

    ${MQ.FROM_M} {
        scroll-margin-top: ${headerSizes.M};
    }

    ${MQ.FROM_XL} {
        scroll-margin-top: ${headerSizes.XL};
    }
`;

export const topPad = css<topPadProps>`
    transition: top 0.2s ease-in-out;
    ${(props) =>
        props.$headerVisible
            ? css`
                  top: ${headerSizes.S};

                  ${MQ.FROM_M} {
                      top: ${headerSizes.M};
                  }

                  ${MQ.FROM_XL} {
                      top: ${headerSizes.XL};
                  }
              `
            : css`
                  top: 0;
              `};
`;

export const topPadOffset = css<topPadProps>`
    transition: top 0.2s ease-in-out;
    ${(props) =>
        props.$headerVisible &&
        css`
            top: ${headerSizesWithOffset.S};

            ${MQ.FROM_M} {
                top: ${headerSizesWithOffset.M};
            }

            ${MQ.FROM_XL} {
                top: ${headerSizesWithOffset.XL};
            }
        `};
`;

interface topPadProps {
    readonly $headerVisible: boolean;
}
