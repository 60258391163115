/* stylelint-disable no-descending-specificity */
import { css } from "styled-components";

import { BodyL, BodyLAlt } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const styledList = css`
    ul,
    ol {
        counter-reset: item;
        margin: 1.75rem 0;
        padding-left: 0;

        ${MQ.FROM_M} {
            margin: 2.1875rem 0;
        }

        ${MQ.FROM_XL} {
            margin: 2.625rem 0;
        }

        li {
            display: block;
            list-style: none;
            min-height: 1.75rem;
            position: relative;
            text-indent: 0;
            width: 100%;
            margin: 0.875rem 0;

            &::before {
                display: flex;
                height: 1.75rem;
                inset: 0;
                justify-content: flex-start;
                position: absolute;
                width: 1.75rem;
            }

            span,
            p {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.checkmark {
            li::before {
                background: currentcolor;
                content: "";
                mask-image: url("data:image/svg+xml;charset=utf8,<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' id='checkPath' d='M24.807 4.10535C25.0249 4.27488 25.0642 4.58902 24.8947 4.80699L10.8947 22.807C10.8066 22.9202 10.6742 22.9901 10.5311 22.9991C10.3879 23.008 10.2479 22.955 10.1464 22.8536L4.14645 16.8536C3.95118 16.6583 3.95118 16.3417 4.14645 16.1465C4.34171 15.9512 4.65829 15.9512 4.85355 16.1465L10.4531 21.746L24.1053 4.19305C24.2749 3.97508 24.589 3.93581 24.807 4.10535Z' fill='black'/></svg>");
                mask-size: auto 100%;
            }
        }
    }

    ${BodyL}, ${BodyLAlt} {
        ol,
        ul {
            li {
                padding-left: 3.0625rem;

                ${MQ.FROM_L} {
                    padding-left: 3.5rem;
                }

                ${MQ.FROM_XL} {
                    min-height: 2.1875rem;
                    padding-left: 4.375rem;
                }
            }

            ${MQ.FROM_XL} {
                li::before {
                    height: 2.1875rem;
                    width: 2.1875rem;
                }

                &.checkmark {
                    li::before {
                        mask-image: url("data:image/svg+xml;charset=utf8,<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' id='checkPath' d='M31.8025 4.10195C32.0224 4.26904 32.0652 4.58271 31.8981 4.80257L12.8981 29.8026C12.8107 29.9175 12.6779 29.9891 12.5339 29.9989C12.3898 30.0087 12.2485 29.9557 12.1464 29.8536L3.14645 20.8536C2.95118 20.6583 2.95118 20.3417 3.14645 20.1465C3.34171 19.9512 3.65829 19.9512 3.85355 20.1465L12.4485 28.7414L31.1019 4.19749C31.269 3.97763 31.5827 3.93486 31.8025 4.10195Z' fill='black'/></svg>");
                    }
                }
            }
        }
    }
`;

export const styledListOL = css`
    ${styledList}

    ol {
        li {
            padding-left: 2.1875rem;

            &::before {
                content: counter(item) ". ";
                counter-increment: item;
            }

            ${MQ.FROM_M} {
                padding-left: 2.625rem;
            }
        }
    }
`;

export const styledListUL = css`
    ${styledList}

    ul {
        li::before {
            background: currentcolor;
            content: "";
            mask-image: url("data:image/svg+xml;charset=utf8,<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 13.5H28' stroke='black'/></svg>");
            mask-size: auto 100%;
        }

        li {
            padding-left: 42px;

            ${MQ.FROM_L} {
                padding-left: 49px;
            }

            ${MQ.FROM_XL} {
                padding-left: 56px;
            }
        }
    }

    ${BodyL}, ${BodyLAlt} {
        ul {
            ${MQ.FROM_XL} {
                li::before {
                    background: currentcolor;
                    content: "";
                    mask-image: url("data:image/svg+xml;charset=utf8,<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 17.5C0 17.2239 0.223858 17 0.5 17H34.5C34.7761 17 35 17.2239 35 17.5C35 17.7761 34.7761 18 34.5 18H0.5C0.223858 18 0 17.7761 0 17.5Z' fill='black'/></svg>");
                    mask-size: auto 100%;
                }
            }
        }
    }
`;
