import { useSelector } from "react-redux";

import FormState, { SingleFormState } from "types/state/FormState";
import RootState from "types/state/RootState";

const useForm = (identifier: string): SingleFormState | undefined => {
    return useSelector((state: RootState): SingleFormState | undefined =>
        state.form?.forms?.find(
            (form: SingleFormState) => form.id === identifier,
        ),
    );
};

export const useForms = (): FormState => {
    return useSelector((state: RootState): FormState => state.form);
};

export default useForm;
