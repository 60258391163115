import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NewsListingPageProps from "./NewsListingPageProps";

const NewsListingPage = loadable(
    (): Promise<DefaultComponent<NewsListingPageProps>> =>
        import(/* webpackChunkName: "NewsListingPage" */ "./NewsListingPage"),
);
const NewsListingPageLoader = (props: NewsListingPageProps): ReactElement => (
    <NewsListingPage {...props} />
);
export default NewsListingPageLoader;
