import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ButtonProps from "./ButtonProps";

const Button = loadable(
    (): Promise<DefaultComponent<ButtonProps>> =>
        import(/* webpackChunkName: "Button" */ "./Button"),
);
const ButtonLoader = (props: ButtonProps): ReactElement => (
    <Button {...props} />
);
export default ButtonLoader;
