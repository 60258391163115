import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import FrequentlyAskedQuestionList from "components/FrequentlyAskedQuestionList";
import Section from "components/Section";
import { useHeader } from "hooks";

import {
    Container,
    Heading,
    Questions,
} from "./FrequentlyAskedQuestionListSection.styled";
import FrequentlyAskedQuestionListSectionProps from "./FrequentlyAskedQuestionListSectionProps";

const FrequentlyAskedQuestionListSection = ({
    heading,
    questionsList,
    theme = "blue",
    contentTheme = "cyan",
    actionTheme = "cyan",
    displayOption = "Left",
    identifier,
    anchorName,
}: FrequentlyAskedQuestionListSectionProps): ReactElement => {
    const headerState = useHeader();
    return (
        <ThemeProvider
            theme={{
                theme: theme,
                contentTheme: contentTheme,
                actionTheme: actionTheme,
                displayOption: displayOption,
            }}
        >
            <Section theme={theme} alignItems="center">
                <Container key={identifier} id={anchorName}>
                    {heading && (
                        <Heading
                            $headerVisible={headerState.visible}
                            dangerouslySetInnerHTML={{ __html: heading }}
                        />
                    )}

                    {questionsList && (
                        <Questions>
                            <FrequentlyAskedQuestionList
                                {...questionsList}
                                identifier={identifier}
                                anchorName={anchorName}
                                theme={theme}
                                contentTheme={contentTheme}
                            />
                        </Questions>
                    )}
                </Container>
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(FrequentlyAskedQuestionListSection);
