import React, { ReactElement } from "react";

import { Wrapper, Link } from "./PageEditLink.styled";
import PageEditLinkProps from "./PageEditLinkProps";

const PageEditLink = ({ pageEditUrl }: PageEditLinkProps): ReactElement => {
    return (
        <Wrapper aria-labelledby="editPageLink">
            <Link id="editPageLink" href={pageEditUrl}>
                Edit Page
            </Link>
        </Wrapper>
    );
};

export default React.memo(PageEditLink);
