import { AnyAction, Reducer } from "redux";

import {
    PAGE_NOT_FOUND,
    SET_PAGE_DATA,
    APPEND_PAGE_DATA,
    SET_PAGE_THEME,
    PAGE_ERROR,
} from "store/actionTypes";
import EpiPage, { EpiPagingProps, EpiPageablePage } from "types/EpiPage";

export const pageContentInitialState: EpiPage | EpiPageablePage = {
    component: "PageNotFound",
};

const pageContentReducer: Reducer = (
    state: EpiPage | EpiPageablePage = pageContentInitialState,
    action: AnyAction,
): EpiPage => {
    //console.dir(action.type); //eslint-disable-line no-console
    switch (action.type) {
        case SET_PAGE_DATA:
            return {
                ...action.payload,
            };
        case APPEND_PAGE_DATA:
            return {
                ...state,
                // Appended data
                ...{
                    paginatedList: getAppendedPaginatedList(
                        action.payload as EpiPageablePage,
                        state as EpiPageablePage,
                    ),
                },
            };
        case PAGE_NOT_FOUND:
            return {
                ...action.payload.data,
                httpStatus: action.payload.status,
            };
        case PAGE_ERROR:
            return {
                ...action.payload.data,
                httpStatus: action.payload.status,
            };
        case SET_PAGE_THEME:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

const getAppendedPaginatedList = (
    append: EpiPageablePage,
    pageable: EpiPageablePage,
): EpiPagingProps => {
    return {
        ...append.paginatedList,
        items: pageable?.paginatedList?.items
            ? pageable.paginatedList.items.concat(append?.paginatedList?.items)
            : append?.paginatedList?.items,
    };
};

export default pageContentReducer;
