import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ActionEntryPointListProps from "./ActionEntryPointListProps";

const ActionEntryPointList = loadable(
    (): Promise<DefaultComponent<ActionEntryPointListProps>> =>
        import(
            /* webpackChunkName: "ActionEntryPointList" */ "./ActionEntryPointList"
        ),
);
const ActionEntryPointListLoader = (
    props: ActionEntryPointListProps,
): ReactElement => <ActionEntryPointList {...props} />;
export default ActionEntryPointListLoader;
