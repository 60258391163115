import { styled } from "styled-components";

export const AppContainer = styled.div`
    min-height: 100vh;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;
