import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import VideoListProps from "./VideoListProps";

const VideoList = loadable(
    (): Promise<DefaultComponent<VideoListProps>> =>
        import(/* webpackChunkName: "VideoList" */ "./VideoList"),
);
const VideoListLoader = (props: VideoListProps): ReactElement => (
    <VideoList {...props} />
);
export default VideoListLoader;
