import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import StartPageProps from "./StartPageProps";

const StartPage = loadable(
    (): Promise<DefaultComponent<StartPageProps>> =>
        import(/* webpackChunkName: "StartPage" */ "./StartPage"),
);
const StartPageLoader = (props: StartPageProps): ReactElement => (
    <StartPage {...props} />
);
export default StartPageLoader;
