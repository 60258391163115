import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import HeaderProps from "./HeaderProps";

const Header = loadable(
    (): Promise<DefaultComponent<HeaderProps>> =>
        import(/* webpackChunkName: "Header" */ "./Header"),
);
const HeaderLoader = (props: HeaderProps): ReactElement => (
    <Header {...props} />
);
export default HeaderLoader;
