import { useSelector } from "react-redux";

import AppState from "types/state/AppState";
import RootState from "types/state/RootState";

const useAppState = (): AppState => {
    return useSelector((state: RootState): AppState => state.app);
};

export default useAppState;
