import {
    withAITracking,
    AppInsightsErrorBoundary,
    AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import ErrorBoundary from "components/ErrorBoundary";
import ErrorPage from "pages/ErrorPage";
import { ai, getReactPlugin } from "utils/createTelemetryService";

import {
    TelemetryProviderProps,
    TelemetryProviderState,
} from "./TelemetryProviderProps";

let reactPlugin: any = null; // eslint-disable-line @typescript-eslint/no-explicit-any
class TelemetryProvider extends Component<
    TelemetryProviderProps,
    TelemetryProviderState
> {
    state = {
        initialized: false,
    };

    componentDidMount() {
        const { history, instrumentationKey } = this.props;
        const { initialized } = this.state;
        if (!initialized && instrumentationKey && history) {
            ai.initialize(instrumentationKey, history);
            this.setState({ initialized: true });
            reactPlugin = getReactPlugin();
        }
    }

    render() {
        const { children } = this.props;
        const { initialized } = this.state;
        return (
            <>
                {initialized ? (
                    <AppInsightsErrorBoundary
                        onError={() => <ErrorPage />}
                        appInsights={reactPlugin}
                    >
                        <AppInsightsContext.Provider value={reactPlugin}>
                            <>{children}</>
                        </AppInsightsContext.Provider>
                    </AppInsightsErrorBoundary>
                ) : (
                    <ErrorBoundary>
                        <>{children}</>
                    </ErrorBoundary>
                )}
            </>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, TelemetryProvider));
