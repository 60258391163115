import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProductPageProps from "./ProductPageProps";

const ProductPage = loadable(
    (): Promise<DefaultComponent<ProductPageProps>> =>
        import(/* webpackChunkName: "ProductPage" */ "./ProductPage"),
);
const ProductPageLoader = (props: ProductPageProps): ReactElement => (
    <ProductPage {...props} />
);
export default ProductPageLoader;
