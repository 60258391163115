import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import BlockQuoteProps from "./BlockQuoteProps";

const BlockQuote = loadable(
    (): Promise<DefaultComponent<BlockQuoteProps>> =>
        import(/* webpackChunkName: "BlockQuote" */ "./BlockQuote"),
);
const BlockQuoteLoader = (props: BlockQuoteProps): ReactElement => (
    <BlockQuote {...props} />
);
export default BlockQuoteLoader;
