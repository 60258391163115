import { css } from "styled-components";

import { MQ } from "./mediaQueries";
import { BASE_MAX_SIZE } from "./settings";

export const GUTTER = {
    XL: "3.5rem",
    MD: "2.1875rem",
    SM: "1.75rem",
};

export const gap = css`
    gap: ${GUTTER.SM};

    ${MQ.FROM_M} {
        gap: ${GUTTER.MD};
    }

    ${MQ.FROM_XL} {
        gap: ${GUTTER.XL};
    }
`;

export const columnGap = css`
    column-gap: ${GUTTER.SM};

    ${MQ.FROM_M} {
        column-gap: ${GUTTER.MD};
    }

    ${MQ.FROM_XL} {
        column-gap: ${GUTTER.XL};
    }
`;

export const gridprops = css`
    --base-max-size: ${BASE_MAX_SIZE}px;
    --grid-column-gutter: ${GUTTER.SM};
    --grid-column-size: calc((100vw - (5 * var(--grid-column-gutter))) / 4);

    ${MQ.FROM_M} {
        --grid-column-gutter: ${GUTTER.MD};
        --grid-column-size: calc(
            (100vw - (13 * var(--grid-column-gutter))) / 12
        );
    }
    ${MQ.FROM_XL} {
        --grid-column-gutter: ${GUTTER.XL};
        --grid-column-size: calc(
            (
                    min(100vw, var(--base-max-size)) -
                        (13 * var(--grid-column-gutter))
                ) / 12
        );
    }
`;
