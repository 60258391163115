import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FooterProps from "./FooterProps";

const Footer = loadable(
    (): Promise<DefaultComponent<FooterProps>> =>
        import(/* webpackChunkName: "Footer" */ "./Footer"),
);
const FooterLoader = (props: FooterProps): ReactElement => (
    <Footer {...props} />
);
export default FooterLoader;
