import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import PageListProps from "./PageListProps";

const PageList = loadable(
    (): Promise<DefaultComponent<PageListProps>> =>
        import(/* webpackChunkName: "PageList" */ "./PageList"),
);
const PageListLoader = (props: PageListProps): ReactElement => (
    <PageList {...props} />
);
export default PageListLoader;
