import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import React, { useEffect, FunctionComponent, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useIsomorphicLayoutEffect, useAppState } from "hooks";
import {
    BEGIN_GET_PAGE_DATA,
    HIDE_SEARCH,
    SET_FREEZE,
    SET_NO_FETCH,
    SET_NO_SCROLL,
    SHOW_HEADER,
} from "store/actionTypes";
import EpiPage from "types/EpiPage";
import { LocationChangeState } from "types/state/LocationChangeState";

import { PageComponentSelectorProps } from "./PageComponentSelectorProps";

const PageComponentSelector = ({
    pageContent,
    componentSelector,
    NotFoundPage,
}: PageComponentSelectorProps): ReactElement => {
    const location = useLocation<LocationChangeState>();
    const dispatch = useDispatch();
    const { noScroll, noFetch, fetchSuccess, fetchErrorStatus } = useAppState();
    const sendDataToGTM = useGTMDispatch();
    const PageComponent: FunctionComponent<EpiPage> | undefined = pageContent
        ? componentSelector(pageContent)
        : undefined;

    useIsomorphicLayoutEffect((): void => {
        if (!pageContent) {
            return;
        }
        const id = location.hash.replace("#", "");
        if (id) {
            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 0);
        }
    }, [pageContent, location.hash, sendDataToGTM]);

    useEffect((): void => {
        if (!noFetch) {
            dispatch({
                type: BEGIN_GET_PAGE_DATA,
                payload: {
                    path: location.pathname,
                    search: location.search,
                },
            });
        } else {
            dispatch({
                type: SET_NO_FETCH,
                payload: false,
            });

            dispatch({
                type: SET_NO_SCROLL,
                payload: false,
            });
        }
    }, [location.pathname, location.search]); //eslint-disable-line

    useEffect((): void => {
        if (fetchSuccess && pageContent?.component) {
            dispatch({ type: HIDE_SEARCH });
            dispatch({ type: SET_FREEZE, payload: false });

            if (!noScroll) {
                dispatch({ type: SHOW_HEADER });
                scrollTo(0, 0);
            }

            sendDataToGTM({
                event: "new_view",
                page_type: pageContent.component.toLowerCase(),
            });
        }
    }, [noScroll, fetchSuccess, pageContent, dispatch, sendDataToGTM]);

    useEffect((): void => {
        if (
            fetchErrorStatus &&
            fetchErrorStatus !== 404 &&
            fetchErrorStatus !== 200
        ) {
            throw fetchErrorStatus;
        }
    }, [fetchErrorStatus]);

    return pageContent ? (
        <>
            {PageComponent ? (
                <PageComponent {...pageContent} />
            ) : (
                <NotFoundPage />
            )}
        </>
    ) : (
        <NotFoundPage />
    );
};

export default React.memo(PageComponentSelector);
