import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import EditorialSectionProps from "./EditorialSectionProps";

const EditorialSection = loadable(
    (): Promise<DefaultComponent<EditorialSectionProps>> =>
        import(/* webpackChunkName: "EditorialSection" */ "./EditorialSection"),
);
const EditorialSectionLoader = (props: EditorialSectionProps): ReactElement => (
    <EditorialSection {...props} />
);
export default EditorialSectionLoader;
