import { useSelector } from "react-redux";

import RootState from "types/state/RootState";

const useApplicationInsightsKey = (): string => {
    return useSelector(
        (state: RootState): string => state.applicationInsightsKey || "",
    );
};

export default useApplicationInsightsKey;
