import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import OfficePageProps from "./OfficePageProps";

const OfficePage = loadable(
    (): Promise<DefaultComponent<OfficePageProps>> =>
        import(/* webpackChunkName: "OfficePage" */ "./OfficePage"),
);
const OfficePageLoader = (props: OfficePageProps): ReactElement => (
    <OfficePage {...props} />
);
export default OfficePageLoader;
