import { useSelector } from "react-redux";

import RootState from "types/state/RootState";

const useLanguagePickerPosition = (): string => {
    return useSelector(
        (state: RootState): string => state.languagePickerPosition || "",
    );
};

export default useLanguagePickerPosition;
