import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";

import { useBrand, usePageContent } from "hooks";
import defaultMetadata from "utils/defaultMetadata";

import { default as faviconClaimslink } from "./img/claimslink-favicon.ico";
import { default as faviconSpgroup } from "./img/spgroup-favicon.ico";
import { default as faviconSphab } from "./img/sphab-favicon.ico";

const Head = (): ReactElement => {
    const pageContent = usePageContent();
    const brand = useBrand();

    const schemaData = pageContent.schemaData;

    const metadata = pageContent.metaData
        ? { ...defaultMetadata, ...pageContent.metaData }
        : defaultMetadata;

    let favicon: string | undefined;
    switch (brand) {
        case "sphab":
            favicon = faviconSphab;
            break;
        case "soderbergpartnersgroup":
            favicon = faviconSpgroup;
            break;
        case "claimslink":
            favicon = faviconClaimslink;
            break;
        default:
            favicon = undefined;
    }

    return (
        <Helmet defer={false}>
            {metadata.charSet && metadata.charSet !== "utf-8" && (
                <meta charSet={metadata.charSet} />
            )}
            {metadata.googleSiteVerification && (
                <meta
                    property="google-site-verification"
                    content={metadata.googleSiteVerification}
                />
            )}
            {metadata.title && <title>{metadata.title}</title>}
            {favicon && (
                <link href={favicon} rel="shortcut icon" type="image/x-icon" />
            )}
            {metadata.themeColor && metadata.themeColor !== "#f5f3f3" && (
                <meta
                    name="msapplication-TileColor"
                    content={metadata.themeColor}
                />
            )}
            {metadata.themeColor && metadata.themeColor !== "#f5f3f3" && (
                <meta name="theme-color" content={metadata.themeColor} />
            )}
            {metadata.canonicalUrl && (
                <link rel="canonical" href={metadata.canonicalUrl} />
            )}
            {metadata.description && (
                <meta name="description" content={metadata.description} />
            )}
            {metadata.keywords && (
                <meta name="keywords" content={metadata.keywords} />
            )}
            {metadata.robots && (
                <meta name="robots" content={metadata.robots} />
            )}
            {metadata.author && (
                <meta name="author" content={metadata.author} />
            )}
            {metadata.copyright && (
                <meta name="copyright" content={metadata.copyright} />
            )}

            {metadata.ogTitle && (
                <meta property="og:title" content={metadata.ogTitle} />
            )}
            {metadata.ogDescription && (
                <meta
                    property="og:description"
                    content={metadata.ogDescription}
                />
            )}
            {metadata.ogImage && (
                <meta property="og:image" content={metadata.ogImage} />
            )}
            {metadata.ogImageWidth && (
                <meta
                    property="og:image:width"
                    content={metadata.ogImageWidth.toString()}
                />
            )}
            {metadata.ogImageHeight && (
                <meta
                    property="og:image:height"
                    content={metadata.ogImageHeight.toString()}
                />
            )}
            {metadata.ogUrl && (
                <meta property="og:url" content={metadata.ogUrl} />
            )}
            {metadata.ogLocale && (
                <meta property="og:locale" content={metadata.ogLocale} />
            )}
            {metadata.ogType && (
                <meta property="og:type" content={metadata.ogType} />
            )}
            {metadata.twitterTitle && (
                <meta
                    property="twitter:title"
                    content={metadata.twitterTitle}
                />
            )}
            {metadata.twitterDescription && (
                <meta
                    property="twitter:description"
                    content={metadata.twitterDescription}
                />
            )}
            {metadata.twitterImage && (
                <meta
                    property="twitter:image"
                    content={metadata.twitterImage}
                />
            )}
            {metadata.twitterUrl && (
                <meta property="twitter:url" content={metadata.twitterUrl} />
            )}
            {metadata.languageUrls &&
                metadata.languageUrls.map((languageUrl, index) => (
                    <link
                        rel="alternate"
                        hrefLang={languageUrl.language}
                        href={languageUrl.url}
                        key={index}
                    />
                ))}
            {schemaData && (
                <script type="application/ld+json">{schemaData}</script>
            )}
        </Helmet>
    );
};

export default React.memo(Head);
