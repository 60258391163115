import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SearchResultsPageProps from "./SearchResultsPageProps";

const SearchResultsPage = loadable(
    (): Promise<DefaultComponent<SearchResultsPageProps>> =>
        import(
            /* webpackChunkName: "SearchResultsPage" */ "./SearchResultsPage"
        ),
);
const SearchResultsPageLoader = (
    props: SearchResultsPageProps,
): ReactElement => <SearchResultsPage {...props} />;
export default SearchResultsPageLoader;
