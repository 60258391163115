import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import OpenPositionsListingPageProps from "./OpenPositionsListingPageProps";

const OpenPositionsListingPage = loadable(
    (): Promise<DefaultComponent<OpenPositionsListingPageProps>> =>
        import(
            /* webpackChunkName: "OpenPositionsListingPage" */ "./OpenPositionsListingPage"
        ),
);
const OpenPositionsListingPageLoader = (
    props: OpenPositionsListingPageProps,
): ReactElement => <OpenPositionsListingPage {...props} />;
export default OpenPositionsListingPageLoader;
