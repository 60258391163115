import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FeaturedNewsProps from "./FeaturedNewsProps";

const FeaturedNews = loadable(
    (): Promise<DefaultComponent<FeaturedNewsProps>> =>
        import(/* webpackChunkName: "FeaturedNews" */ "./FeaturedNews"),
);
const FeaturedNewsLoader = (props: FeaturedNewsProps): ReactElement => (
    <FeaturedNews {...props} />
);
export default FeaturedNewsLoader;
