import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import MortgageApplicationPageProps from "./MortgageApplicationPageProps";

const MortgageApplicationPage = loadable(
    (): Promise<DefaultComponent<MortgageApplicationPageProps>> =>
        import(
            /* webpackChunkName: "MortgageApplicationPage" */ "./MortgageApplicationPage"
        ),
);
const MortgageApplicationPageLoader = (
    props: MortgageApplicationPageProps,
): ReactElement => <MortgageApplicationPage {...props} />;
export default MortgageApplicationPageLoader;
