import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import BusinessAreaLogoProps from "./BusinessAreaLogoProps";

const BusinessAreaLogo = loadable(
    (): Promise<DefaultComponent<BusinessAreaLogoProps>> =>
        import(/* webpackChunkName: "BusinessAreaLogo" */ "./BusinessAreaLogo"),
);
const BusinessAreaLogoLoader = (props: BusinessAreaLogoProps): ReactElement => (
    <BusinessAreaLogo {...props} />
);
export default BusinessAreaLogoLoader;
