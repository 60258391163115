import { useSelector } from "react-redux";

import RootState from "types/state/RootState";
import SystemMessageState from "types/state/SystemMessageState";

const useSystemMessage = (): SystemMessageState => {
    return useSelector(
        (state: RootState): SystemMessageState => state.systemMessage,
    );
};

export default useSystemMessage;
