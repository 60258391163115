import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SupportPageProps from "./SupportPageProps";

const SupportPage = loadable(
    (): Promise<DefaultComponent<SupportPageProps>> =>
        import(/* webpackChunkName: "SupportPage" */ "./SupportPage"),
);
const SupportPageLoader = (props: SupportPageProps): ReactElement => (
    <SupportPage {...props} />
);
export default SupportPageLoader;
