import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SliderProps from "./SliderProps";

const Slider = loadable(
    (): Promise<DefaultComponent<SliderProps>> =>
        import(/* webpackChunkName: "Slider" */ "./Slider"),
);
const SliderLoader = (props: SliderProps): ReactElement => (
    <Slider {...props} />
);
export default SliderLoader;
