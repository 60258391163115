import React, { ReactElement } from "react";

import { BlockComponentSelectorProps } from "./BlockComponentSelectorProps";

function BlockComponentSelector({
    content,
    componentSelector,
}: BlockComponentSelectorProps): ReactElement {
    const BlockComponent = componentSelector(content);
    if (BlockComponent) {
        return <BlockComponent {...content} />;
    } else {
        return <></>;
    }
}

export default React.memo(BlockComponentSelector);
