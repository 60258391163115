import { useSelector } from "react-redux";

import FooterState from "types/state/FooterState";
import RootState from "types/state/RootState";

const useFooter = (): FooterState => {
    return useSelector((state: RootState): FooterState => state.footer);
};

export default useFooter;
