import React, { ReactElement, useCallback } from "react";
import { ThemeProvider } from "styled-components";

import { HelperText, Wrapper } from "./Button.styled";
import { SubmitButtonProps } from "./ButtonProps";

import Button from ".";

const SubmitButton = ({
    elementName,
    label,
    description,
    actionTheme,
    icon,
    isLink,
    displayOption = "Full",
    disabled = false,
    style,
    className,
    editPropertyName,
    onClick,
    onKeyDown,
    gtmClickType,
    type = "submit",
}: SubmitButtonProps): ReactElement => {
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (onClick) onClick(event);
        },
        [onClick],
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (onKeyDown) onKeyDown(event);
        },
        [onKeyDown],
    );

    return (
        <ThemeProvider
            theme={{
                displayOption: displayOption,
            }}
        >
            <Wrapper>
                <Button
                    className={className}
                    name={elementName}
                    disabled={disabled}
                    actionTheme={actionTheme}
                    variant="primary"
                    size="large"
                    type={type}
                    style={style}
                    isLink={isLink}
                    icon={icon}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    editPropertyName={editPropertyName}
                    gtmClickType={gtmClickType}
                >
                    {label}
                </Button>
                {description && <HelperText>{description}</HelperText>}
            </Wrapper>
        </ThemeProvider>
    );
};

export default SubmitButton;
