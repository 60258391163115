import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import CallToActionSlimSectionProps from "./CallToActionSlimSectionProps";

const CallToActionSlimSection = loadable(
    (): Promise<DefaultComponent<CallToActionSlimSectionProps>> =>
        import(
            /* webpackChunkName: "CallToActionSlimSection" */ "./CallToActionSlimSection"
        ),
);
const CallToActionSlimSectionLoader = (
    props: CallToActionSlimSectionProps,
): ReactElement => <CallToActionSlimSection {...props} />;
export default CallToActionSlimSectionLoader;
