import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import UniqueSellingPointListProps from "./UniqueSellingPointListProps";

const UniqueSellingPointList = loadable(
    (): Promise<DefaultComponent<UniqueSellingPointListProps>> =>
        import(
            /* webpackChunkName: "UniqueSellingPointList" */ "./UniqueSellingPointList"
        ),
);
const UniqueSellingPointListLoader = (
    props: UniqueSellingPointListProps,
): ReactElement => <UniqueSellingPointList {...props} />;
export default UniqueSellingPointListLoader;
