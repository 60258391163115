/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, ReactChild } from "react";

import Link from "components/Link";
import LinkInterface, { StandardLinkInterface } from "types/LinkInterface";

const isStandarLinkInterface = (
    object: any,
): object is StandardLinkInterface => {
    if (object) {
        return "url" in object;
    } else {
        return false;
    }
};

const constructStandardLinkElement = (
    { url, linkText }: StandardLinkInterface,
    contentId: string | undefined,
    _ContainerComponent?: FunctionComponent,
    _LinkComponent?: FunctionComponent,
    keyPreName = "key",
    index = 0,
): ReactChild => {
    if (url && linkText) {
        const key = `${keyPreName}-${contentId}${index}${url}${linkText}`;
        const linkProps = { to: url };
        const linkElement = _LinkComponent ? (
            <_LinkComponent {...linkProps}>{linkText}</_LinkComponent>
        ) : (
            <Link {...linkProps}>{linkText}</Link>
        );
        const linkContainer = _ContainerComponent ? (
            <_ContainerComponent key={key}>{linkElement}</_ContainerComponent>
        ) : (
            <li key={key}>{linkElement}</li>
        );
        return linkContainer;
    } else {
        return <></>;
    }
};

const constructLinkElement = (
    obj: LinkInterface,
    contentId: string | undefined,
    _ContainerComponent?: FunctionComponent,
    _LinkComponent?: FunctionComponent,
    keyPreName = "key",
    index = 0,
): ReactChild => {
    if (isStandarLinkInterface(obj)) {
        return constructStandardLinkElement(
            obj,
            contentId,
            _ContainerComponent,
            _LinkComponent,
            keyPreName,
            index,
        );
    }
    return <></>;
};

const _linkItemMapper = (
    contentId: string | undefined,
    items: LinkInterface[],
    keyPreName: string,
    _ContainerComponent?: FunctionComponent,
    _LinkComponent?: FunctionComponent,
): ReactChild[] => {
    const itemMapper = (obj: LinkInterface, index: number): ReactChild => {
        return constructLinkElement(
            obj,
            contentId,
            _ContainerComponent,
            _LinkComponent,
            keyPreName,
            index,
        );
    };

    return items.map(itemMapper);
};

export { isStandarLinkInterface };
