import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProcessProps from "./ProcessProps";

const Process = loadable(
    (): Promise<DefaultComponent<ProcessProps>> =>
        import(/* webpackChunkName: "Process" */ "./Process"),
);
const ProcessLoader = (props: ProcessProps): ReactElement => (
    <Process {...props} />
);
export default ProcessLoader;
