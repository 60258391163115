import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import PromotionProps from "./PromotionProps";

const Promotion = loadable(
    (): Promise<DefaultComponent<PromotionProps>> =>
        import(/* webpackChunkName: "Promotion" */ "./Promotion"),
);
const PromotionLoader = (props: PromotionProps): ReactElement => (
    <Promotion {...props} />
);
export default PromotionLoader;
