import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FileUploadProps from "./FileUploadProps";

const FileUpload = loadable(
    (): Promise<DefaultComponent<FileUploadProps>> =>
        import(/* webpackChunkName: "FileUpload" */ "./FileUpload"),
);
const FileUploadLoader = (props: FileUploadProps): ReactElement => (
    <FileUpload {...props} />
);
export default FileUploadLoader;
