import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import IconProps from "./IconProps";

const Icon = loadable(
    (): Promise<DefaultComponent<IconProps>> =>
        import(/* webpackChunkName: "Icon" */ "./Icon"),
);
const IconLoader = (props: IconProps): ReactElement => <Icon {...props} />;
export default IconLoader;
