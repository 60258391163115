export const LOADING_BEGIN = "LOADING_BEGIN";
export const LOADING_COMPLETE = "LOADING_COMPLETE";
export const FETCH_PAGE_DATA_BEGIN = "FETCH_PAGE_DATA_BEGIN";
export const FETCH_PAGE_DATA_SUCCESS = "FETCH_PAGE_DATA_SUCCESS";
export const FETCH_PAGE_DATA_ERROR = "FETCH_PAGE_DATA_ERROR";
export const FETCH_PAGE_DATA_COMPLETE = "FETCH_PAGE_DATA_COMPLETE";
export const APPEND_DATA_BEGIN = "APPEND_DATA_BEGIN";
export const APPEND_DATA_SUCCESS = "APPEND_DATA_SUCCESS";
export const APPEND_DATA_ERROR = "APPEND_DATA_ERROR";
export const APPEND_DATA_COMPLETE = "APPEND_DATA_COMPLETE";
export const APPEND_PAGE_DATA = "APPEND_PAGE_DATA";
export const SET_NO_FETCH = "SET_NO_FETCH";
export const SET_NO_SCROLL = "SET_NO_SCROLL";
export const BEGIN_GET_PAGE_DATA = "BEGIN_GET_PAGE_DATA";
export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";
export const PAGE_ERROR = "PAGE_ERROR";
export const SET_PAGE_DATA = "SET_PAGE_DATA";
export const SHOW_HEADER = "SHOW_HEADER";
export const HIDE_HEADER = "HIDE_HEADER";
export const SHOW_SEARCH = "SHOW_SEARCH";
export const HIDE_SEARCH = "HIDE_SEARCH";
export const SET_PAGE_THEME = "SET_PAGE_THEME";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const SUBMIT_FORM_CLEAN = "SUBMIT_FORM_CLEAN";
export const SUBMIT_FORM_BEGIN = "SUBMIT_FORM_BEGIN";
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_ERROR = "SUBMIT_FORM_ERROR";
export const SUBMIT_FORM_COMPLETE = "SUBMIT_FORM_COMPLETE";
export const SET_FREEZE = "SET_FREEZE";
export const FREEZE = "FREEZE";
export const UNFREEZE = "UNFREEZE";
