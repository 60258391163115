import { AxiosResponse } from "axios";
import { takeLatest, all, put, call } from "redux-saga/effects";

import {
    BEGIN_GET_PAGE_DATA,
    SET_PAGE_DATA,
    LOADING_BEGIN,
    FETCH_PAGE_DATA_BEGIN,
    FETCH_PAGE_DATA_SUCCESS,
    FETCH_PAGE_DATA_COMPLETE,
    LOADING_COMPLETE,
    SET_NO_SCROLL,
    SUBMIT_FORM_CLEAN,
    APPEND_DATA_BEGIN,
    APPEND_PAGE_DATA,
    APPEND_DATA_SUCCESS,
    APPEND_DATA_ERROR,
    APPEND_DATA_COMPLETE,
    PAGE_NOT_FOUND,
    PAGE_ERROR,
    FETCH_PAGE_DATA_ERROR,
} from "store/actionTypes";
import EpiPage from "types/EpiPage";
import { getData } from "utils/fetch";

import {
    BeginGetPageDataAction,
    BeginAppendPageDataAction,
} from "./pageContentActionTypes";

function* getPagaDataSaga(action: BeginGetPageDataAction): Generator {
    const { path, search } = action.payload;
    yield put({ type: LOADING_BEGIN });
    yield put({
        type: FETCH_PAGE_DATA_BEGIN,
    });
    try {
        const response = (yield call(
            getData,
            search && search.length > 0
                ? `${path}${search}&asjson=true`
                : `${path}?asjson=true`,
        )) as AxiosResponse<EpiPage>;
        const { data } = response;

        if (data.redirect?.location) {
            window.location.replace(data.redirect.location);
            return;
        }
        yield put({
            type: SET_PAGE_DATA,
            payload: data,
        });
        yield put({
            type: FETCH_PAGE_DATA_SUCCESS,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const { response } = error;
        yield put({
            type: response?.status === 404 ? PAGE_NOT_FOUND : PAGE_ERROR,
            payload: error.response,
        });
        yield put({
            type: FETCH_PAGE_DATA_ERROR,
            payload: response.status,
        });
    } finally {
        yield put({
            type: FETCH_PAGE_DATA_COMPLETE,
        });
        yield put({
            type: LOADING_COMPLETE,
        });
        yield put({
            type: SET_NO_SCROLL,
            payload: false,
        });
        yield put({
            type: SUBMIT_FORM_CLEAN,
        });
    }
}

function* appendPagaDataSaga(action: BeginAppendPageDataAction): Generator {
    const { path, search, page, pageSize } = action.payload;
    yield put({ type: LOADING_BEGIN });
    yield put({
        type: FETCH_PAGE_DATA_BEGIN,
    });
    try {
        const query = `${
            search && search.length > 0
                ? search + "&asJson=true"
                : "?asJson=true"
        }&p=${page}&ps=${pageSize}`;

        const response = (yield call(
            getData,
            `${path}${query}`,
        )) as AxiosResponse<EpiPage>;
        const { data } = response;

        yield put({
            type: APPEND_PAGE_DATA,
            payload: data,
        });
        yield put({
            type: APPEND_DATA_SUCCESS,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const { response } = error;
        yield put({
            type: APPEND_DATA_ERROR,
            payload: response.status,
        });
    } finally {
        yield put({
            type: APPEND_DATA_COMPLETE,
        });
        yield put({
            type: LOADING_COMPLETE,
        });
        yield put({
            type: SET_NO_SCROLL,
            payload: false,
        });
    }
}

function* pageContentSagas(): Generator {
    yield all([
        takeLatest(BEGIN_GET_PAGE_DATA, getPagaDataSaga),
        takeLatest(APPEND_DATA_BEGIN, appendPagaDataSaga),
    ]);
}

export default pageContentSagas;
