import { takeLatest, all, put } from "redux-saga/effects";

import { FREEZE, SET_FREEZE, UNFREEZE } from "store/actionTypes";

function* setFreezeSaga(action: BeginFreezection): Generator {
    if (typeof document !== "undefined") {
        const freeze = action.payload;

        if (freeze) {
            document.body.classList.add("freeze");
            yield put({ type: FREEZE });
        } else {
            document.body.classList.remove("freeze");
            yield put({ type: UNFREEZE });
        }
    }
}

function* appStateSagas(): Generator {
    yield all([takeLatest(SET_FREEZE, setFreezeSaga)]);
}

export default appStateSagas;

export interface BeginFreezection {
    type: typeof SET_FREEZE;
    payload: boolean;
}
