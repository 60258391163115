import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { ChunkExtractor } from "@loadable/server";
import React, { ReactElement } from "react";
import { Provider } from "react-redux";
import { StaticRouter, StaticRouterProps } from "react-router";
import { BrowserRouter, BrowserRouterProps } from "react-router-dom";
import { StyleSheetManager } from "styled-components";

import { AppContainer, ContentContainer } from "App/App.styled";
import BlockComponentSelector from "components/BlockComponentSelector";
import Head from "components/Head";
import Section from "components/Section";
import store from "store";
import { GlobalStyle } from "style/components/GlobalStyle";
import defaultPreloadedState from "utils/defaultPreloadedstate";
import { addFullRefreshPropertiesAttributes } from "utils/episerver";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

global.ChunkExtractor = ChunkExtractor;

const PreviewWrapper = ({
    model,
    state,
}: {
    model?: string;
    state?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}): ReactElement => {
    const contentModel = model ? JSON.parse(model) : state;
    const Router: React.ComponentType<BrowserRouterProps | StaticRouterProps> =
        typeof window !== "undefined" ? BrowserRouter : StaticRouter;

    const content = contentModel?.componentContent;
    const editMode = contentModel.editMode;

    return (
        <Provider store={store(defaultPreloadedState)}>
            <GTMProvider state={{ id: "", injectScript: false }}>
                <StyleSheetManager enableVendorPrefixes={true}>
                    <GlobalStyle />
                    <Router>
                        <AppContainer>
                            <Head key={`head`} />
                            <ContentContainer key={`container`}>
                                {editMode?.isInEditMode === true &&
                                    editMode.fullRefreshProperties &&
                                    editMode.fullRefreshProperties.length >
                                        0 && (
                                        <input
                                            type="hidden"
                                            {...addFullRefreshPropertiesAttributes(
                                                editMode.fullRefreshProperties,
                                            )}
                                        />
                                    )}
                                <Section>
                                    <BlockComponentSelector
                                        componentSelector={
                                            getComponentTypeForContent
                                        }
                                        content={content}
                                    />
                                </Section>
                            </ContentContainer>
                        </AppContainer>
                    </Router>
                </StyleSheetManager>
            </GTMProvider>
        </Provider>
    );
};

export default React.memo(PreviewWrapper);
