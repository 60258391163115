import { AnyAction, Reducer } from "redux";

import NavigationState from "types/state/NavigationState";
export const navigationInitialState: NavigationState = {};

const navigationReducer: Reducer = (
    state: NavigationState = navigationInitialState,
    _action: AnyAction,
): NavigationState => state;

export default navigationReducer;
