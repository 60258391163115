import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import HeaderState from "types/state/HeaderState";
import RootState from "types/state/RootState";

const selectHeader = createSelector(
    (state: RootState) => state.header,
    (header: HeaderState): HeaderState => header,
);
const useHeader = () => useSelector(selectHeader);

export default useHeader;
