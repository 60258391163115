import { AnyAction, Reducer } from "redux";

import {
    SUBMIT_FORM_CLEAN,
    SUBMIT_FORM_BEGIN,
    SUBMIT_FORM_COMPLETE,
    SUBMIT_FORM_ERROR,
    SUBMIT_FORM_SUCCESS,
} from "store/actionTypes";
import FormState from "types/state/FormState";

export const formInitialState: FormState = { forms: [] };

const formReducer: Reducer = (
    state: FormState = formInitialState,
    action: AnyAction,
): FormState => {
    switch (action.type) {
        case SUBMIT_FORM_CLEAN:
            return {
                ...state,
                forms: [],
            };
        case SUBMIT_FORM_BEGIN:
            return {
                ...state,
                forms: [
                    {
                        id: action.id,
                        isSubmitting: true,
                        isSuccess: false,
                        successMessage: undefined,
                        isError: false,
                        errorMessage: undefined,
                    },
                    ...state.forms.filter((form) => form.id !== action.id),
                ],
            };
        case SUBMIT_FORM_ERROR:
            return {
                ...state,
                forms: state.forms.map((form) => {
                    if (form.id !== action.id) {
                        return form;
                    }
                    return {
                        ...form,
                        isError: true,
                        errorMessage: action.payload.errorMessage,
                        validationErrors: action.payload.validationErrors,
                    };
                }),
            };
        case SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                forms: state.forms.map((form) => {
                    if (form.id !== action.id) {
                        return form;
                    }
                    return {
                        ...form,
                        isSuccess: true,
                        successMessage: action.payload.successMessage,
                    };
                }),
            };
        case SUBMIT_FORM_COMPLETE:
        default:
            return {
                ...state,
                forms: state.forms.map((form) => {
                    if (form.id !== action.id) {
                        return form;
                    }
                    return {
                        ...form,
                        isSubmitting: false,
                    };
                }),
            };
    }
};

export default formReducer;
