import { useSelector } from "react-redux";

import EditModeState from "types/state/EditModeState";
import RootState from "types/state/RootState";

const useEditMode = (): EditModeState => {
    return useSelector((state: RootState): EditModeState => state.editMode);
};

export default useEditMode;
