export const BREAKPOINT_VALUE = {
    S: `320`,
    M: `768`,
    L: `992`,
    XL: `1365`,
    XXL: `1680`,
    XXXL: `1920`,
    XXXXL: `2560`,
};

export const BREAKPOINT_NUMBER = {
    S: Number(BREAKPOINT_VALUE.S),
    M: Number(BREAKPOINT_VALUE.M),
    L: Number(BREAKPOINT_VALUE.L),
    XL: Number(BREAKPOINT_VALUE.XL),
    XXL: Number(BREAKPOINT_VALUE.XXL),
    XXXL: Number(BREAKPOINT_VALUE.XXXL),
    XXXXL: Number(BREAKPOINT_VALUE.XXXXL),
};

export const BREAKPOINT = {
    S: `${BREAKPOINT_VALUE.S}px`,
    M: `${BREAKPOINT_VALUE.M}px`,
    L: `${BREAKPOINT_VALUE.L}px`,
    XL: `${BREAKPOINT_VALUE.XL}px`,
};
