import React, { ReactElement } from "react";
import isEqual from "react-fast-compare";
import { ThemeProvider } from "styled-components";

import { addEditAttributes } from "utils/episerver";

import { Wrapper } from "./Section.styled";
import SectionProps from "./SectionProps";

const Section = ({
    id,
    theme,
    colorTheme,
    children,
    alignItems,
    ariaLabel,
    className,
    editPropertyName,
    style,
}: SectionProps): ReactElement => {
    const childItems = <>{children}</>;
    return (
        <ThemeProvider theme={{ theme: theme || colorTheme }}>
            <Wrapper
                id={id}
                aria-label={ariaLabel}
                className={className}
                style={style}
                $alignItems={alignItems}
                {...(editPropertyName
                    ? addEditAttributes(editPropertyName)
                    : {})}
            >
                {childItems}
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(Section, isEqual);
