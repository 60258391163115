import { combineReducers } from "redux";

import EditModeState from "types/state/EditModeState";
import FooterState from "types/state/FooterState";
import GoogleTagManagerType from "types/state/GoogleTagManagerType";
import RootState from "types/state/RootState";
import SystemMessageState from "types/state/SystemMessageState";

import appReducer from "./appStateReducer";
import formReducer from "./formReducer";
import headerReducer from "./headerReducer/headerReducer";
import navigationReducer from "./navigationReducer";
import pageContentReducer from "./pageContentReducer/pageContentReducer";

export const rootReducer = combineReducers<RootState>({
    pageContent: pageContentReducer,
    form: formReducer,
    app: appReducer,
    header: headerReducer,
    navigation: navigationReducer,

    // Read-only properties that is populated by SSR preloaded state.
    footer: (state: FooterState = {}) => state,
    editMode: (state: EditModeState = { isInEditMode: false }) => state,
    brand: (state = "") => state,
    documentLanguage: (state = "sv") => state,
    googleTagManager: (state: GoogleTagManagerType = {}) => state,
    googleMapsKey: (state = "") => state,
    cookieConsent: (state = {}) => state,
    applicationInsightsKey: (state = "") => state,
    languagePickerPosition: (state = "footer") => state,
    systemMessage: (state: SystemMessageState = {}) => state,
});

export default rootReducer;
