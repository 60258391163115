import type { Dispatch, RefObject } from "react";
import { useEffect } from "react";

import { googleMapStyle } from "style/googleMapStyle";

const useMap = (
    ref: RefObject<HTMLElement>,
    setMap: Dispatch<React.SetStateAction<google.maps.Map | undefined>>,
    map?: google.maps.Map,
) => {
    useEffect(() => {
        const validWindow =
            window && typeof window !== "undefined" && window.google;
        if (validWindow && ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, {
                    styles: googleMapStyle,
                }),
            );
        }
    }, [ref, setMap, map]);
};

export default useMap;
