//import "./wdyr"; // Uncomment to enable why did you render (https://github.com/welldone-software/why-did-you-render)

import { loadableReady } from "@loadable/component";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import PreviewWrapper from "components/PreviewWrapper";
import EpiPage from "types/EpiPage";
import RootState from "types/state/RootState";
import { getData } from "utils/fetch";

import App from "./App";
import store from "./store";

const rootElement = document.getElementById("root");
const previewRootElement = document.getElementById("preview-root");
const isPreview = !!previewRootElement;

if (!rootElement && !isPreview)
    throw new Error("Failed to find the root element");

const searchParams = new URLSearchParams(window.location.search);
const tracking =
    !searchParams.has("tracking") || searchParams.get("tracking") === "true";

if (
    process.env.NODE_ENV === "development" &&
    typeof module.hot !== "undefined" &&
    !isPreview
) {
    const rootDataPath = `${window.location.pathname}${
        window.location.search
            ? window.location.search + "&asrootdata=true"
            : "?asrootdata=true"
    }`;

    getData(rootDataPath)
        .then((res) => {
            const state: RootState<EpiPage> = {
                ...res.data,
                app: {
                    loading: false,
                    noFetch: true,
                    isTracking: tracking,
                },
            };

            ReactDOM.render(
                <Provider store={store(state)}>
                    <App />
                </Provider>,
                rootElement,
            );
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
            const {
                response: { data },
            } = error;

            const state: RootState<EpiPage> = {
                ...data,
                pageContent: {
                    ...data?.pageContent,
                    httpStatus: 404,
                },
                app: { loading: false, noFetch: true },
            };

            ReactDOM.render(
                <Provider store={store(state)}>
                    <App />
                </Provider>,
                rootElement,
            );
        });
} else {
    const state: RootState<EpiPage> = window.__PRELOADED_STATE__
        ? {
              ...JSON.parse(window.__PRELOADED_STATE__),
              app: { loading: false, noFetch: true, isTracking: tracking },
          }
        : {};

    if (isPreview) {
        loadableReady(() =>
            ReactDOM.hydrate(
                <PreviewWrapper state={state} />,
                previewRootElement,
            ),
        );
    } else {
        loadableReady(() =>
            ReactDOM.hydrate(
                <Provider store={store(state)}>
                    <App />
                </Provider>,
                rootElement,
            ),
        );
    }
}
