import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ImageSectionProps from "./ImageSectionProps";

const ImageSection = loadable(
    (): Promise<DefaultComponent<ImageSectionProps>> =>
        import(/* webpackChunkName: "ImageSection" */ "./ImageSection"),
);
const ImageSectionLoader = (props: ImageSectionProps): ReactElement => (
    <ImageSection {...props} />
);
export default ImageSectionLoader;
