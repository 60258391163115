import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import VideoProps from "./VideoProps";

const Video = loadable(
    (): Promise<DefaultComponent<VideoProps>> =>
        import(/* webpackChunkName: "Video" */ "./Video"),
);
const VideoLoader = (props: VideoProps): ReactElement => <Video {...props} />;
export default VideoLoader;
