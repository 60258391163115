import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import KeyFiguresListProps from "./KeyFiguresListProps";

const KeyFiguresList = loadable(
    (): Promise<DefaultComponent<KeyFiguresListProps>> =>
        import(/* webpackChunkName: "KeyFiguresList" */ "./KeyFiguresList"),
);
const KeyFiguresListLoader = (props: KeyFiguresListProps): ReactElement => (
    <KeyFiguresList {...props} />
);
export default KeyFiguresListLoader;
