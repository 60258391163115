import { styled } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import { styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { topPadOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(4, [col-start] 1fr);
    position: relative;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Heading = styled.h2.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    ${styleHeadingXL};
    align-self: start;
    grid-column: col-start 1 / span 4;
    justify-self: ${theme("displayOption", {
        Left: "flex-start",
        Right: "flex-end",
    })};
    margin-bottom: 0;
    margin-top: 0;
    position: sticky;
    text-align: ${theme("displayOption", {
        Left: "left",
        Right: "right",
    })};

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 8",
            Right: "col-start 5 / span 8",
        })};
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 6",
            Right: "col-start 7 / span 6",
        })};
        top: 4.375rem;
        ${topPadOffset};
    }

    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 6",
            Right: "col-start 8 / span 6",
        })};
    }
`;

export const Questions = styled.div`
    grid-column: col-start 1 / span 4;
    margin-bottom: 1.625rem;
    margin-top: 2.1875rem;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 5 / span 8",
            Right: "col-start 1 / span 8",
        })};
        margin-top: 3.5rem;
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 7 / span 5",
            Right: "col-start 1 / span 5",
        })};
        margin-top: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 7 / span 5",
            Right: "col-start 2 / span 5",
        })};
        margin-top: 5.25rem;
    }
`;
