import { Reducer, AnyAction } from "redux";

import {
    LOADING_BEGIN,
    LOADING_COMPLETE,
    FETCH_PAGE_DATA_BEGIN,
    FETCH_PAGE_DATA_SUCCESS,
    FETCH_PAGE_DATA_ERROR,
    FETCH_PAGE_DATA_COMPLETE,
    APPEND_DATA_BEGIN,
    APPEND_DATA_SUCCESS,
    APPEND_DATA_ERROR,
    APPEND_DATA_COMPLETE,
    SET_NO_SCROLL,
    SET_NO_FETCH,
} from "store/actionTypes";
import AppState from "types/state/AppState";

export const appInitialState: AppState = {
    loading: false,
    isFetching: undefined,
    fetchSuccess: undefined,
    isAppending: undefined,
    appendSuccess: undefined,
    noScroll: undefined,
    fetchErrorStatus: undefined,
    isTracking: true,
};

const appStateReducer: Reducer = (
    state: AppState = appInitialState,
    action: AnyAction,
): AppState => {
    switch (action.type) {
        case LOADING_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case LOADING_COMPLETE:
            return {
                ...state,
                loading: false,
            };
        case FETCH_PAGE_DATA_BEGIN:
            return {
                ...state,
                isFetching: true,
                fetchSuccess: undefined,
            };
        case FETCH_PAGE_DATA_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fetchSuccess: true,
            };
        case FETCH_PAGE_DATA_ERROR:
            return {
                ...state,
                isFetching: false,
                fetchSuccess: false,
                fetchErrorStatus: action.payload,
            };
        case FETCH_PAGE_DATA_COMPLETE:
            return {
                ...state,
                isFetching: undefined,
                fetchSuccess: undefined,
            };
        case APPEND_DATA_BEGIN:
            return {
                ...state,
                isAppending: true,
                appendSuccess: undefined,
            };
        case APPEND_DATA_SUCCESS:
            return {
                ...state,
                isAppending: false,
                appendSuccess: true,
            };
        case APPEND_DATA_ERROR:
            return {
                ...state,
                isAppending: false,
                appendSuccess: false,
                fetchErrorStatus: action.payload,
            };
        case APPEND_DATA_COMPLETE:
            return {
                ...state,
                isAppending: undefined,
                appendSuccess: undefined,
            };
        case SET_NO_SCROLL:
            return {
                ...state,
                noScroll: action.payload,
            };
        case SET_NO_FETCH:
            return {
                ...state,
                noFetch: action.payload,
            };
        default:
            return state;
    }
};

export default appStateReducer;
