import { FormHelperText, FormControl } from "@mui/material";
import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import iconLongArrowRight from "icons/longArrowRight28.svg"; // eslint-disable-line import/no-unresolved
import { hideVisually } from "style/components/A11Y";
import { styleLabelM, styleLabelS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";

import {
    backgroundColor,
    borderColor,
    color,
    borderColorHover,
    colorHoverIconOnly,
    colorHover,
    backgroundColorHover,
    disabledBackgroundColor,
    disabledBorderColor,
    disabledColor,
    backgroundColorEmbed,
    borderColorEmbed,
    colorEmbed,
    borderColorHoverEmbed,
    colorHoverEmbed,
    backgroundColorHoverEmbed,
} from "./Button.colors";

const verticalAlign = theme("size", {
    small: "-0.5625rem",
    medium: "-0.5625rem",
    large: "-0.75rem",
});

const buttonDefaults = css`
    all: unset;
    ${styleLabelM};
    border-radius: 0.25rem;
    box-sizing: border-box;
    display: inline-block;
    letter-spacing: 0.1em;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    @media (hover: hover) {
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }

    /* Need some explicit ones for firefox */
    &[type="submit"]:focus-visible,
    &[type="button"]:focus-visible,
    &[type="reset"]:focus-visible,
    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }

    span,
    svg {
        color: inherit;
    }

    &.embedded {
        margin: 1rem 0;
    }
`;

export const buttonStyle = css<{
    $iconOnly?: boolean;
    $variant?: "primary" | "outline" | "simple";
}>`
    ${buttonDefaults};
    backdrop-filter: ${(props) =>
        !props.$iconOnly &&
        props.$variant === "outline" &&
        props.theme.actionTheme === "white"
            ? "var(--button-on-image-blur)"
            : "none"};
    background: var(
        ${(props) =>
            props.$iconOnly ? "--color-transparent" : backgroundColor}
    );
    border: 1px solid var(${borderColor});
    color: var(${color});
    padding: ${(props) =>
        props.$iconOnly
            ? 0
            : theme("size", {
                  small: "0.375rem",
                  medium: "0.375rem 0.8125rem",
                  large: "0.8125rem 2.5625rem",
              })};
    ${(props) =>
        props.$iconOnly &&
        css`
            border: none;
        `}
    ${(props) =>
        props.$iconOnly &&
        props.theme.actionTheme === "white" &&
        css`
            filter: var(--shadow-on-image);
        `}

    ${MQ.FROM_M} {
        padding: ${(props) =>
            props.$iconOnly
                ? 0
                : theme("size", {
                      medium: "0.8125rem 1.6875rem",
                      large: "1.25rem 4.3125rem",
                  })};
    }

    ${MQ.FROM_XL} {
        padding: ${(props) =>
            props.$iconOnly
                ? 0
                : theme("size", {
                      medium: "0.8125rem 1.6875rem",
                      large: "1.6875rem 6.0625rem",
                  })};
    }

    &:hover {
        border-bottom-color: var(${borderColor});
    }

    @media (hover: hover) {
        &:hover {
            border-color: var(${borderColorHover});
            color: var(
                ${(props) =>
                    props.$iconOnly ? colorHoverIconOnly : colorHover}
            );

            &:not(.disabled) {
                background: var(
                    ${(props) =>
                        props.$iconOnly
                            ? "--transparent"
                            : backgroundColorHover}
                );
            }
        }
    }

    span {
        ${(props) => props.$iconOnly && hideVisually};
    }

    &.disabled {
        background: var(${disabledBackgroundColor});
        border-color: var(${disabledBorderColor});
        color: var(${disabledColor});

        span {
            color: var(${disabledColor});
        }

        &:hover {
            border-bottom-color: var(${disabledBorderColor});
        }

        @media (hover: hover) {
            &:hover {
                cursor: not-allowed;
                text-decoration: none;
                border-color: var(${disabledBorderColor});
                color: var(${disabledColor});

                span {
                    color: var(${disabledColor});
                }
            }
        }
    }
`;

export const embedButtonStyle = css`
    ${buttonDefaults};
    background: var(${backgroundColorEmbed});
    border: 1px solid var(${borderColorEmbed});
    color: var(${colorEmbed});
    padding: 0.375rem 0.8125rem;

    ${MQ.FROM_M} {
        padding: 0.8125rem 1.6875rem;
    }

    ${MQ.FROM_XL} {
        padding: 0.8125rem 1.6875rem;
    }

    &:hover {
        border-bottom-color: var(${borderColorEmbed});
    }

    &::after {
        background-color: var(${colorEmbed});
        content: "";
        display: inline-block;
        height: 1.75rem;
        margin-left: 0.4375rem;
        mask-image: url(${iconLongArrowRight});
        vertical-align: middle;
        width: 4.375rem;
    }

    @media (hover: hover) {
        &:hover {
            border-color: var(${borderColorHoverEmbed});
            color: var(${colorHoverEmbed});
            background: var(${backgroundColorHoverEmbed});

            &::after {
                background-color: var(${colorHoverEmbed});
            }
        }
    }
`;

export const StyledLink = styled(NavLink).attrs<{
    $iconOnly?: boolean;
    $variant?: "primary" | "outline" | "simple";
}>(() => ({}))`
    ${buttonStyle};
`;

export const StyledButton = styled.button.attrs<{
    $iconOnly?: boolean;
    $variant?: "primary" | "outline" | "simple";
}>(() => ({}))`
    ${buttonStyle};
`;

export const ButtonGroup = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const IconArrow = styled(Icon)`
    margin-left: 0.4375rem;
    vertical-align: ${verticalAlign};

    ${MQ.FROM_M} {
        margin-left: 0.875rem;
    }
`;

export const StyledIcon = styled(Icon).attrs<{
    readonly $iconOnly: boolean;
}>(() => ({}))`
    margin-right: ${(props) =>
        theme("a11y", {
            true: "0",
            false: props.$iconOnly ? "0" : "0.4375rem",
        })};
    vertical-align: ${verticalAlign};

    ${MQ.FROM_M} {
        ${(props) =>
            theme("a11y", {
                true: "0",
                false: props.$iconOnly ? "0" : "0.875rem",
            })};
    }
`;

export const Wrapper = styled(FormControl)`
    && {
        grid-column: col-start 1 / span 2;
        margin-top: 0.875rem;

        ${MQ.FROM_M} {
            margin-top: 1.3125rem;
            grid-column: ${theme("displayOption", {
                Full: "auto / span 6",
                Half: "auto / span 3",
                OneThird: "auto / span 2",
                TwoThirds: "auto / span 4",
            })};
        }

        ${MQ.FROM_XL} {
            margin-top: 1.75rem;
        }

        &:first-child {
            margin-top: 0;
        }
    }
`;

export const HelperText = styled(FormHelperText)`
    && {
        ${styleLabelS};
        color: var(--color-black-o50);
        margin-left: 0;
    }
`;

export interface ButtonStyleProp {
    readonly $variant?: "primary" | "outline";
    readonly $iconOnly?: boolean;
}
