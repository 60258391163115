import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import BlockQuoteSectionProps from "./BlockQuoteSectionProps";

const BlockQuoteSection = loadable(
    (): Promise<DefaultComponent<BlockQuoteSectionProps>> =>
        import(
            /* webpackChunkName: "BlockQuoteSection" */ "./BlockQuoteSection"
        ),
);
const BlockQuoteSectionLoader = (
    props: BlockQuoteSectionProps,
): ReactElement => <BlockQuoteSection {...props} />;
export default BlockQuoteSectionLoader;
