import { styled } from "styled-components";

import { MQ } from "style/mediaQueries";
import { BASE_MAX_SIZE } from "style/settings";

import { GUTTER } from "../grid";

export const Wrapper = styled.main`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const Section = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div<containerProps>`
    max-width: ${BASE_MAX_SIZE}px;
    padding: 2.1875rem ${GUTTER.SM} 3.5rem ${GUTTER.SM};
    width: 100%;
    ${(props) => props.textAlign && `text-align: ${props.textAlign};`}

    ${MQ.FROM_M} {
        padding: 3.5rem ${GUTTER.MD} 6.125rem ${GUTTER.MD};
    }

    ${MQ.FROM_XL} {
        padding: 4.375rem ${GUTTER.XL} 8.75rem ${GUTTER.XL};
    }
`;

interface containerProps {
    readonly textAlign?: "left" | "center" | "right";
}
