import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ContactPageProps from "./ContactPageProps";

const ContactPage = loadable(
    (): Promise<DefaultComponent<ContactPageProps>> =>
        import(/* webpackChunkName: "ContactPage" */ "./ContactPage"),
);
const ContactPageLoader = (props: ContactPageProps): ReactElement => (
    <ContactPage {...props} />
);
export default ContactPageLoader;
