import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NewsletterSignupProps from "./NewsletterSignupProps";

const NewsletterSignup = loadable(
    (): Promise<DefaultComponent<NewsletterSignupProps>> =>
        import(/* webpackChunkName: "NewsletterSignup" */ "./NewsletterSignup"),
);
const NewsletterSignupLoader = (props: NewsletterSignupProps): ReactElement => (
    <NewsletterSignup {...props} />
);
export default NewsletterSignupLoader;
