import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormResultItemProps from './FormResultItemProps';

const FormResultItem = loadable(
    (): Promise<DefaultComponent<FormResultItemProps>> =>
        import(/* webpackChunkName: "FormInfoBox" */ "./FormResultItem"),
);
const FormResultItemLoader = (props: FormResultItemProps): ReactElement => <FormResultItem {...props} />;
export default FormResultItemLoader;
