import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import TextBlockProps from "./TextBlockProps";

const TextBlock = loadable(
    (): Promise<DefaultComponent<TextBlockProps>> =>
        import(/* webpackChunkName: "TextBlock" */ "./TextBlock"),
);
const TextBlockLoader = (props: TextBlockProps): ReactElement => (
    <TextBlock {...props} />
);
export default TextBlockLoader;
