import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import BlockQuoteListProps from "./BlockQuoteListProps";

const BlockQuoteList = loadable(
    (): Promise<DefaultComponent<BlockQuoteListProps>> =>
        import(/* webpackChunkName: "BlockQuoteList" */ "./BlockQuoteList"),
);
const BlockQuoteListLoader = (props: BlockQuoteListProps): ReactElement => (
    <BlockQuoteList {...props} />
);
export default BlockQuoteListLoader;
