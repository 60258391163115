/* eslint-disable import/no-unresolved */
import { ISnippetsParams } from "@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import RootState from "types/state/RootState";

const selectId = (state: RootState) => state.googleTagManager?.id;
const selectDataLayerName = (state: RootState) =>
    state.googleTagManager?.dataLayerName;
const selectCustomdomain = (state: RootState) =>
    state.googleTagManager?.customDomain;
const selectEnvironment = (state: RootState) =>
    state.googleTagManager?.environment;
const selectEnvironmentGtmAuth = (state: RootState) =>
    state.googleTagManager?.environment?.gtm_auth;
const selectEnvironmentGtmPreview = (state: RootState) =>
    state.googleTagManager?.environment?.gtm_preview;

const selectGoogleTagManager = createSelector(
    [
        selectId,
        selectDataLayerName,
        selectCustomdomain,
        selectEnvironment,
        selectEnvironmentGtmAuth,
        selectEnvironmentGtmPreview,
    ],
    (
        id: string | undefined,
        dataLayerName,
        customDomain,
        environment,
        gtmAuth,
        gtmPreview,
    ): ISnippetsParams => {
        if (id)
            return {
                id: id,
                ...(dataLayerName && {
                    dataLayerName: dataLayerName,
                }),
                ...(customDomain && {
                    customDomain: customDomain,
                }),
                ...(environment &&
                    Object.keys(environment).length !== 0 && {
                        environment: {
                            ...(gtmAuth && {
                                gtm_auth: gtmAuth,
                            }),
                            ...(gtmPreview && {
                                gtm_preview: gtmPreview,
                            }),
                        },
                    }),
            } as ISnippetsParams;
        else return { injectScript: false } as ISnippetsParams;
    },
);

const useGoogleTagManager = (): ISnippetsParams =>
    useSelector(selectGoogleTagManager);

export default useGoogleTagManager;
