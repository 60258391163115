import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormSectionProps from "./FormSectionProps";

const FormSection = loadable(
    (): Promise<DefaultComponent<FormSectionProps>> =>
        import(/* webpackChunkName: "FormSection" */ "./FormSection"),
);
const FormSectionLoader = (props: FormSectionProps): ReactElement => (
    <FormSection {...props} />
);
export default FormSectionLoader;
