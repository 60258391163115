import React, { Component, ErrorInfo, ReactNode } from "react";

import ErrorPage from "pages/ErrorPage";

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(_error: Error, _errorInfo: ErrorInfo) {
        // console.error(
        //     "Uncaught error caught in ErrorBoundary:",
        //     _error,
        //     _errorInfo
        // );
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
