import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import OpenPositionsSectionProps from "./OpenPositionListSectionProps";

const OpenPositionsSection = loadable(
    (): Promise<DefaultComponent<OpenPositionsSectionProps>> =>
        import(
            /* webpackChunkName: "OpenPositionsSection" */ "./OpenPositionListSection"
        ),
);
const OpenPositionsSectionLoader = (
    props: OpenPositionsSectionProps,
): ReactElement => <OpenPositionsSection {...props} />;
export default OpenPositionsSectionLoader;
