import { AnyAction, Reducer } from "redux";

import {
    HIDE_HEADER,
    HIDE_SEARCH,
    SHOW_HEADER,
    SHOW_SEARCH,
} from "store/actionTypes";
import HeaderState from "types/state/HeaderState";

export const headerInitialState: HeaderState = {
    visible: true,
    searchOverlayVisible: false,
};

const headerReducer: Reducer = (
    state: HeaderState = headerInitialState,
    { type }: AnyAction,
): HeaderState => {
    switch (type) {
        case SHOW_HEADER:
            return {
                ...state,
                visible: true,
            };
        case HIDE_HEADER:
            return {
                ...state,
                visible: false,
            };
        case SHOW_SEARCH:
            return {
                ...state,
                searchOverlayVisible: true,
            };
        case HIDE_SEARCH:
            return {
                ...state,
                searchOverlayVisible: false,
            };
        default:
            return state;
    }
};

export default headerReducer;
