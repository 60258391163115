import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormParagraphTextProps from "./FormParagraphTextProps";

const FormParagraphText = loadable(
    (): Promise<DefaultComponent<FormParagraphTextProps>> =>
        import(
            /* webpackChunkName: "FormParagraphText" */ "./FormParagraphText"
        ),
);
const FormParagraphTextLoader = (
    props: FormParagraphTextProps,
): ReactElement => <FormParagraphText {...props} />;
export default FormParagraphTextLoader;
