import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import HiddenFieldProps from "./HiddenFieldProps";

const HiddenField = loadable(
    (): Promise<DefaultComponent<HiddenFieldProps>> =>
        import(/* webpackChunkName: "HiddenField" */ "./HiddenField"),
);
const HiddenFieldLoader = (props: HiddenFieldProps): ReactElement => (
    <HiddenField {...props} />
);
export default HiddenFieldLoader;
