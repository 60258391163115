import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import AccordionListProps from "./AccordionListProps";

const AccordionList = loadable(
    (): Promise<DefaultComponent<AccordionListProps>> =>
        import(/* webpackChunkName: "AccordionList" */ "./AccordionList"),
);
const AccordionListLoader = (props: AccordionListProps): ReactElement => (
    <AccordionList {...props} />
);
export default AccordionListLoader;
