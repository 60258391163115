import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import RelatedContentProps from "./RelatedContentProps";

const RelatedContent = loadable(
    (): Promise<DefaultComponent<RelatedContentProps>> =>
        import(/* webpackChunkName: "RelatedContent" */ "./RelatedContent"),
);
const RelatedContentLoader = (props: RelatedContentProps): ReactElement => (
    <RelatedContent {...props} />
);
export default RelatedContentLoader;
