import { fork } from "redux-saga/effects";

import appStateSagas from "store/reducers/appStateReducer/appStateSagas";
import formSagas from "store/reducers/formReducer/submitFormSagas";
import pageContentSagas from "store/reducers/pageContentReducer/pageContentSagas";

export default function* rootSaga(): Generator {
    yield fork(pageContentSagas);
    yield fork(appStateSagas);
    yield fork(formSagas);
}
