import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import OfficeListingPageProps from "./OfficeListingPageProps";

const OfficeListingPage = loadable(
    (): Promise<DefaultComponent<OfficeListingPageProps>> =>
        import(
            /* webpackChunkName: "OfficeListingPage" */ "./OfficeListingPage"
        ),
);
const OfficeListingPageLoader = (
    props: OfficeListingPageProps,
): ReactElement => <OfficeListingPage {...props} />;
export default OfficeListingPageLoader;
