import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import PageGridProps from "./PageGridProps";

const PageGrid = loadable(
    (): Promise<DefaultComponent<PageGridProps>> =>
        import(/* webpackChunkName: "PageGrid" */ "./PageGrid"),
);
const PageGridLoader = (props: PageGridProps): ReactElement => (
    <PageGrid {...props} />
);
export default PageGridLoader;
