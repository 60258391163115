import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SystemMessageProps from "./SystemMessageProps";

const SystemMessage = loadable(
    (): Promise<DefaultComponent<SystemMessageProps>> =>
        import(/* webpackChunkName: "SystemMessage" */ "./SystemMessage"),
);
const SystemMessageLoader = (props: SystemMessageProps): ReactElement => (
    <SystemMessage {...props} />
);
export default SystemMessageLoader;
