import { styled } from "styled-components";
import theme from "styled-theming";

const backgroundColor = theme("theme", {
    undefined: "--color-transparent",
    default: "--background-default",
    lightgray: "--background-default",
    blue: "--block-blue-default",
    cyan: "--block-cyan-default",
    white: "--block-white-default",
    black: "--block-black-default",
    coral: "--block-coral-default",
});

const themeColor = theme("theme", {
    undefined: "--text-on-neutral-primary",
    default: "--text-on-neutral-primary",
    white: "--text-on-neutral-primary",
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
    image: "--text-on-neutral-inverted",
    coral: "--text-on-coral-primary",
});

export const Wrapper = styled.div.attrs<{
    $alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
}>(() => ({}))`
    align-items: ${(props) => props.$alignItems || "flex-start"};
    background: var(${backgroundColor});
    color: var(${themeColor});
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;
