import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FactBoxProps from "./FactBoxProps";

const FactBox = loadable(
    (): Promise<DefaultComponent<FactBoxProps>> =>
        import(/* webpackChunkName: "FactBox" */ "./FactBox"),
);
const FactBoxLoader = (props: FactBoxProps): ReactElement => (
    <FactBox {...props} />
);
export default FactBoxLoader;
