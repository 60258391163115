import { useSelector } from "react-redux";
import { createSelector } from "reselect";

import HeaderState from "types/state/HeaderState";
import RootState from "types/state/RootState";

const selectHeaderVisible = createSelector(
    (state: RootState) => state.header,
    (header: HeaderState) => header.visible,
);
const useHeaderVisible = () => useSelector(selectHeaderVisible);

export default useHeaderVisible;
