import { useSelector } from "react-redux";

import RootState from "types/state/RootState";

const useIsInEditMode = (): boolean => {
    return useSelector(
        (state: RootState): boolean => state.editMode.isInEditMode,
    );
};

export default useIsInEditMode;
