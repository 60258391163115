import MetaDataInterface from "types/MetaDataInterface";

const defaultMetadata: MetaDataInterface = {
    title: "",
    charSet: "utf-8",
    description: "",
    keywords: "",
    image: "",
    themeColor: "#f5f3f3",
    author: "",
    language: "",
    copyright: "",
    noScript: "",
    canonicalUrl: "",
    url: "",
    robots: "",
    ogTitle: "",
    ogDescription: "",
    ogImage: "",
    ogUrl: "",
    twitterTitle: "",
    twitterDescription: "",
    twitterImage: "",
    twitterUrl: "",
    languageUrls: [],
    googleSiteVerification: "",
};

export default defaultMetadata;
