import theme from "styled-theming";

export const backgroundColor = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--button-on-cyan-primary",
        cyan: "--button-on-blue-primary",
        coral: "--button-on-blue-primary-alt",
        white: "--button-on-image-primary",
        lightgray_blue: "--button-on-neutral-primary",
        lightgray_cyan: "--button-on-neutral-primary-alt2",
        lightgray_coral: "--button-on-neutral-primary-alt1",
        cyan_blue: "--button-on-cyan-primary",
        blue_cyan: "--button-on-blue-primary",
        blue_coral: "--button-on-blue-primary-alt",
    },
    outline: {
        blue: "--color-transparent",
        black: "--color-transparent",
        cyan: "--color-transparent",
        white: "--button-on-image-outline",
        coral: "--color-transparent",
        lightgray_black: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
    simple: {
        blue: "--color-transparent",
        black: "--color-transparent",
        cyan: "--color-transparent",
        white: "--button-on-image-outline",
        coral: "--color-transparent",
        lightgray_black: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
});

export const backgroundColorEmbed = theme("buttonTheme", {
    black: "--color-transparent",
    blue: "--button-on-cyan-primary",
    coral: "--button-on-blue-primary-alt",
    cyan: "--button-on-blue-primary",
    lightgray_black: "--color-transparent",
    lightgray_blue: "--button-on-neutral-primary",
    cyan_blue: "--button-on-cyan-primary",
    blue_cyan: "--button-on-blue-primary",
});

export const backgroundColorHover = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--button-on-cyan-primary-hover",
        cyan: "--button-on-blue-primary-hover",
        coral: "--button-on-blue-primary-alt-hover",
        white: "--button-on-image-primary-hover",
        lightgray_blue: "--button-on-neutral-primary-hover",
        lightgray_cyan: "--button-on-neutral-primary-alt2-hover",
        lightgray_coral: "--button-on-neutral-primary-alt1-hover",
        cyan_blue: "--button-on-cyan-primary-hover",
        blue_cyan: "--button-on-blue-primary-hover",
        blue_coral: "--button-on-blue-primary-alt-hover",
    },
    outline: {
        blue: "--button-on-cyan-outline-hover",
        black: "--button-on-neutral-outline-hover",
        cyan: "--button-on-blue-outline-hover",
        white: "--button-on-image-outline-hover",
        coral: "--button-on-blue-outline-alt-hover",
        lightgray_black: "--button-on-neutral-outline-hover",
        lightgray_coral: "--button-on-neutral-outline-alt-hover",
        cyan_blue: "--button-on-cyan-outline-hover",
        blue_cyan: "--button-on-blue-outline-hover",
        blue_coral: "--button-on-blue-outline-alt-hover",
    },
    simple: {
        blue: "--color-transparent",
        black: "--color-transparent",
        cyan: "--color-transparent",
        white: "--color-transparent",
        coral: "--color-transparent",
        lightgray_black: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
});

export const backgroundColorHoverEmbed = theme("buttonTheme", {
    black: "--button-on-cyan-primary-hover",
    blue: "--button-on-cyan-primary-hover",
    cyan: "--button-on-blue-primary-hover",
    coral: "--button-on-blue-primary-alt-hover",
    lightgray_black: "--button-on-neutral-outline-hover",
    lightgray_blue: "--button-on-neutral-primary-hover",
    cyan_blue: "--button-on-cyan-primary-hover",
    blue_cyan: "--button-on-blue-primary-hover",
});

export const color = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--text-on-blue-primary",
        coral: "--text-on-coral-primary",
        cyan: "--text-on-cyan-primary",
        white: "--text-on-neutral-primary",
        lightgray_blue: "--text-on-blue-primary",
        lightgray_cyan: "--text-on-cyan-primary",
        lightgray_coral: "--text-on-coral-primary",
        cyan_blue: "--text-on-blue-primary",
        blue_cyan: "--text-on-cyan-primary",
        blue_coral: "--text-on-coral-primary",
    },
    outline: {
        blue: "--text-on-cyan-primary",
        black: "--text-on-neutral-primary",
        cyan: "--text-on-neutral-primary",
        white: "--text-on-image-primary",
        coral: "--text-on-blue-primary-alt",
        lightgray_black: "--text-on-neutral-primary",
        lightgray_coral: "--text-on-neutral-primary-alt2",
        cyan_blue: "--text-on-cyan-primary",
        blue_cyan: "--text-on-blue-primary",
        blue_coral: "--text-on-blue-primary-alt",
    },
    simple: {
        blue: "--text-on-cyan-secondary",
        black: "--text-on-neutral-secondary",
        cyan: "--text-on-blue-secondary",
        white: "--text-on-image-primary",
        coral: "--text-on-blue-primary-alt",
        lightgray_black: "--text-on-neutral-primary",
        lightgray_coral: "--text-on-neutral-primary-alt2",
        cyan_blue: "--text-on-cyan-primary",
        blue_cyan: "--text-on-blue-primary",
        blue_coral: "--text-on-blue-primary-alt",
    },
});

export const colorEmbed = theme("buttonTheme", {
    black: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    coral: "--text-on-coral-primary",
    cyan: "--text-on-cyan-primary",
    lightgray_black: "--text-on-neutral-primary",
    lightgray_blue: "--text-on-blue-primary",
    cyan_blue: "--text-on-blue-primary",
    blue_cyan: "--text-on-cyan-primary",
});

export const colorHover = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--text-on-blue-primary",
        coral: "--text-on-coral-primary",
        cyan: "--text-on-cyan-primary",
        white: "--text-on-neutral-primary",
        lightgray_blue: "--text-on-blue-primary",
        lightgray_cyan: "--text-on-cyan-primary",
        lightgray_coral: "--text-on-coral-primary",
        cyan_blue: "--text-on-blue-primary",
        blue_cyan: "--text-on-cyan-primary",
        blue_coral: "--text-on-coral-primary",
    },
    outline: {
        blue: "--text-on-blue-primary",
        black: "--text-on-blue-primary",
        cyan: "--text-on-cyan-primary",
        white: "--text-on-neutral-primary",
        coral: "--text-on-coral-primary",
        lightgray_black: "--text-on-blue-primary",
        lightgray_coral: "--text-on-coral-primary",
        cyan_blue: "--text-on-blue-primary",
        blue_cyan: "--text-on-cyan-primary",
        blue_coral: "--text-on-coral-primary",
    },
    simple: {
        blue: "--text-on-cyan-primary",
        black: "--text-on-neutral-link",
        cyan: "--text-on-blue-primary-alt",
        white: "--text-on-image-primary",
        coral: "--text-on-blue-primary-alt",
        lightgray_black: "--text-on-neutral-primary",
        lightgray_coral: "--text-on-neutral-primary-alt2",
        cyan_blue: "--text-on-cyan-primary",
        blue_cyan: "--text-on-blue-primary",
        blue_coral: "--text-on-blue-primary-alt",
    },
});

export const colorHoverEmbed = theme("buttonTheme", {
    black: "--text-on-blue-primary",
    blue: "--text-on-blue-primary",
    coral: "--text-on-coral-primary",
    cyan: "--text-on-cyan-primary",
    lightgray_black: "--text-on-blue-primary",
    lightgray_blue: "--text-on-blue-primary",
    cyan_blue: "--text-on-blue-primary",
    blue_cyan: "--text-on-cyan-primary",
});

export const colorHoverIconOnly = theme.variants("buttonTheme", "$variant", {
    primary: {
        black: "--text-on-neutral-primary",
        blue: "--text-on-cyan-primary",
        coral: "--text-on-blue-primary-alt",
        cyan: "--text-on-blue-primary",
        white: "--text-on-image-primary",
        lightgray_blue: "--text-on-neutral-primary-alt1",
        lightgray_cyan: "--text-on-neutral-primary",
        lightgray_coral: "--text-on-neutral-primary-alt2",
        cyan_blue: "--text-on-cyan-primary",
        blue_cyan: "--text-on-blue-primary",
        blue_coral: "--text-on-blue-primary-alt",
    },
    outline: {
        black: "--button-on-neutral-outline-hover",
        blue: "--button-on-cyan-outline-hover",
        coral: "--button-on-blue-outline-alt-hover",
        cyan: "--button-on-blue-outline-hover",
        white: "--button-on-image-primary-hover",
        lightgray_black: "--button-on-neutral-outline-hover",
        lightgray_coral: "--button-on-neutral-outline-alt-hover",
        cyan_blue: "--button-on-cyan-outline-hover",
        blue_cyan: "--button-on-blue-outline-hover",
        blue_coral: "--button-on-blue-outline-alt-hover",
    },
    simple: {
        black: "--button-on-neutral-outline-hover",
        blue: "--button-on-cyan-outline-hover",
        coral: "--button-on-blue-outline-alt-hover",
        cyan: "--button-on-blue-outline-hover",
        white: "--button-on-image-primary-hover",
        lightgray_black: "--button-on-neutral-outline-hover",
        lightgray_coral: "--button-on-neutral-outline-alt-hover",
        cyan_blue: "--button-on-cyan-outline-hover",
        blue_cyan: "--button-on-blue-outline-hover",
        blue_coral: "--button-on-blue-outline-alt-hover",
    },
});

export const borderColor = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--button-on-cyan-primary",
        cyan: "--button-on-blue-primary",
        coral: "--button-on-blue-primary-alt",
        white: "--button-on-image-primary",
        lightgray_blue: "--button-on-neutral-primary",
        lightgray_cyan: "--button-on-neutral-primary-alt2",
        lightgray_coral: "--button-on-neutral-primary-alt1",
        cyan_blue: "--button-on-cyan-primary",
        blue_cyan: "--button-on-blue-primary",
        blue_coral: "--button-on-blue-primary-alt",
    },
    outline: {
        black: "--button-on-neutral-outline-border",
        blue: "--button-on-cyan-outline-border",
        cyan: "--button-on-blue-outline-border",
        coral: "--button-on-blue-outline-alt-border",
        white: "--button-on-image-outline-border",
        lightgray_black: "--button-on-neutral-outline-border",
        lightgray_coral: "--button-on-neutral-outline-alt-border",
        cyan_blue: "--button-on-cyan-outline-border",
        blue_cyan: "--button-on-blue-outline-border",
        blue_coral: "--button-on-blue-outline-alt-border",
    },
    simple: {
        black: "--color-transparent",
        blue: "--color-transparent",
        cyan: "--color-transparent",
        coral: "--color-transparent",
        white: "--color-transparent",
        lightgray_black: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
});

export const borderColorEmbed = theme("buttonTheme", {
    black: "--button-on-neutral-outline-border",
    blue: "--button-on-cyan-primary",
    cyan: "--button-on-blue-primary",
    coral: "--button-on-blue-primary-alt",
    white: "--button-on-image-primary",
    lightgray_black: "--button-on-neutral-outline-border",
    lightgray_blue: "--button-on-neutral-primary",
    cyan_blue: "--button-on-cyan-primary",
    blue_cyan: "--button-on-blue-primary",
});

export const borderColorHover = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--button-on-cyan-primary-hover",
        cyan: "--button-on-blue-primary-hover",
        coral: "--button-on-blue-primary-alt-hover",
        white: "--button-on-image-primary-hover",
        lightgray_blue: "--button-on-neutral-primary-hover",
        lightgray_cyan: "--button-on-neutral-primary-alt2-hover",
        lightgray_coral: "--button-on-neutral-primary-alt1-hover",
        cyan_blue: "--button-on-cyan-primary-hover",
        blue_cyan: "--button-on-blue-primary-hover",
        blue_coral: "--button-on-blue-primary-alt-hover",
    },
    outline: {
        black: "--button-on-neutral-outline-hover",
        blue: "--button-on-cyan-outline-hover",
        cyan: "--button-on-blue-outline-hover",
        coral: "--button-on-blue-outline-alt-hover",
        white: "--button-on-image-outline-hover",
        lightgray_black: "--button-on-neutral-outline-hover",
        lightgray_coral: "--button-on-neutral-outline-alt-hover",
        cyan_blue: "--button-on-cyan-outline-hover",
        blue_cyan: "--button-on-blue-outline-hover",
        blue_coral: "--button-on-blue-outline-alt-hover",
    },
    simple: {
        black: "--color-transparent",
        blue: "--color-transparent",
        cyan: "--color-transparent",
        coral: "--color-transparent",
        white: "--color-transparent",
        lightgray_black: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
});

export const borderColorHoverEmbed = theme("buttonTheme", {
    black: "--button-on-neutral-outline-hover",
    blue: "--button-on-cyan-primary-hover",
    cyan: "--button-on-blue-primary-hover",
    coral: "--button-on-blue-primary-alt-hover",
    lightgray_black: "--button-on-neutral-outline-hover",
    lightgray_blue: "--button-on-neutral-primary-hover",
    cyan_blue: "--button-on-cyan-primary-hover",
    blue_cyan: "--button-on-blue-primary-hover",
});

export const disabledBackgroundColor = theme.variants(
    "buttonTheme",
    "$variant",
    {
        primary: {
            blue: "--button-on-cyan-disabled",
            cyan: "--button-on-blue-disabled",
            coral: "--button-on-blue-disabled",
            white: "--button-on-image-disabled",
            lightgray_blue: "--button-on-neutral-disabled",
            lightgray_cyan: "--button-on-neutral-disabled",
            lightgray_coral: "--button-on-neutral-disabled",
            cyan_blue: "--button-on-cyan-disabled",
            blue_cyan: "--button-on-blue-disabled",
            blue_coral: "--button-on-blue-disabled",
        },
        outline: {
            blue: "--color-transparent",
            black: "--color-transparent",
            cyan: "--color-transparent",
            white: "--color-transparent",
            coral: "--color-transparent",
            lightgray_black: "--color-transparent",
            lightgray_coral: "--color-transparent",
            cyan_blue: "--color-transparent",
            blue_cyan: "--color-transparent",
            blue_coral: "--color-transparent",
        },
        simple: {
            blue: "--color-transparent",
            black: "--color-transparent",
            cyan: "--color-transparent",
            white: "--color-transparent",
            coral: "--color-transparent",
            lightgray_black: "--color-transparent",
            lightgray_coral: "--color-transparent",
            cyan_blue: "--color-transparent",
            blue_cyan: "--color-transparent",
            blue_coral: "--color-transparent",
        },
    },
);

export const disabledColor = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--block-cyan-default",
        cyan: "--block-blue-default",
        coral: "--block-blue-default",
        white: "--text-on-neutral-secondary",
        lightgray_blue: "--block-neutral-default",
        lightgray_cyan: "--block-neutral-default",
        lightgray_coral: "--block-neutral-default",
        cyan_blue: "--block-cyan-default",
        blue_cyan: "--block-blue-default",
        blue_coral: "--block-blue-default",
    },
    outline: {
        black: "--text-on-neutral-disabled",
        blue: "--text-on-cyan-disabled",
        cyan: "--text-on-blue-disabled",
        coral: "--text-on-blue-disabled",
        white: "--text-on-image-disabled",
        lightgray_black: "--text-on-neutral-disabled",
        lightgray_coral: "--text-on-neutral-disabled",
        cyan_blue: "--text-on-cyan-disabled",
        blue_cyan: "--text-on-blue-disabled",
        blue_coral: "--text-on-blue-disabled",
    },
    simple: {
        black: "--text-on-neutral-disabled",
        blue: "--text-on-cyan-disabled",
        cyan: "--text-on-blue-disabled",
        coral: "--text-on-blue-disabled",
        white: "--text-on-image-disabled",
        lightgray_black: "--text-on-neutral-disabled",
        lightgray_coral: "--text-on-neutral-disabled",
        cyan_blue: "--text-on-cyan-disabled",
        blue_cyan: "--text-on-blue-disabled",
        blue_coral: "--text-on-blue-disabled",
    },
});

export const disabledBorderColor = theme.variants("buttonTheme", "$variant", {
    primary: {
        blue: "--color-transparent",
        coral: "--color-transparent",
        cyan: "--color-transparent",
        white: "--color-transparent",
        lightgray_blue: "--color-transparent",
        lightgray_cyan: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
    outline: {
        black: "--button-on-neutral-disabled",
        blue: "--button-on-cyan-disabled",
        cyan: "--button-on-blue-disabled",
        coral: "--button-on-blue-disabled",
        white: "--button-on-image-disabled",
        lightgray_black: "--button-on-neutral-disabled",
        lightgray_coral: "--button-on-neutral-disabled",
        cyan_blue: "--button-on-cyan-disabled",
        blue_cyan: "--button-on-blue-disabled",
        blue_coral: "--button-on-blue-disabled",
    },
    simple: {
        blue: "--color-transparent",
        black: "--color-transparent",
        cyan: "--color-transparent",
        white: "--color-transparent",
        coral: "--color-transparent",
        lightgray_black: "--color-transparent",
        lightgray_coral: "--color-transparent",
        cyan_blue: "--color-transparent",
        blue_cyan: "--color-transparent",
        blue_coral: "--color-transparent",
    },
});
