import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import WhiteLabelPageProps from "./WhiteLabelPageProps";

const WhiteLabelPage = loadable(
    (): Promise<DefaultComponent<WhiteLabelPageProps>> =>
        import(/* webpackChunkName: "WhiteLabelPage" */ "./WhiteLabelPage"),
);
const WhiteLabelPageLoader = (props: WhiteLabelPageProps): ReactElement => (
    <WhiteLabelPage {...props} />
);
export default WhiteLabelPageLoader;
