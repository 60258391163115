import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NewsPageProps from "./NewsPageProps";

const NewsPage = loadable(
    (): Promise<DefaultComponent<NewsPageProps>> =>
        import(/* webpackChunkName: "NewsPage" */ "./NewsPage"),
);
const NewsPageLoader = (props: NewsPageProps): ReactElement => (
    <NewsPage {...props} />
);
export default NewsPageLoader;
