import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
    ApplicationInsights,
    ITelemetryItem,
} from "@microsoft/applicationinsights-web";

let reactPlugin: ReactPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

export const createTelemetryService = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const initialize = (instrumentationKey: string, browserHistory: any) => {
        if (!browserHistory) {
            throw new Error("Could not initialize Telemetry Service");
        }

        if (!instrumentationKey) {
            throw new Error("Instrumentation key not provided ");
        }

        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                disablePageUnloadEvents: ["unload"],
                connectionString: instrumentationKey,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        });

        appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ "ai.cloud.role": "Front end" });
        });

        appInsights.loadAppInsights();
    };
    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;
export const getReactPlugin = () => reactPlugin;
