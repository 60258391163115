import { BREAKPOINT_NUMBER } from "./breakpoints";

export const MQ = {
    FROM_M: `@media (min-width: ${BREAKPOINT_NUMBER.M}px)`,
    FROM_L: `@media (min-width: ${BREAKPOINT_NUMBER.L}px)`,
    FROM_XL: `@media (min-width: ${BREAKPOINT_NUMBER.XL}px)`,
    FROM_XXL: `@media (min-width: ${BREAKPOINT_NUMBER.XXL}px)`,
    FROM_XXXL: `@media (min-width: ${BREAKPOINT_NUMBER.XXXL}px)`,
    FROM_XXXXL: `@media (min-width: ${BREAKPOINT_NUMBER.XXXXL}px)`,
};
