import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProfileListProps from "./ProfileListProps";

const ProfileList = loadable(
    (): Promise<DefaultComponent<ProfileListProps>> =>
        import(/* webpackChunkName: "ProfileList" */ "./ProfileList"),
);
const ProfileListLoader = (props: ProfileListProps): ReactElement => (
    <ProfileList {...props} />
);
export default ProfileListLoader;
