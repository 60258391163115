import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import MapSectionProps from "./MapSectionProps";

const MapSection = loadable(
    (): Promise<DefaultComponent<MapSectionProps>> =>
        import(/* webpackChunkName: "MapSection" */ "./MapSection"),
);
const MapSectionLoader = (props: MapSectionProps): ReactElement => (
    <MapSection {...props} />
);
export default MapSectionLoader;
