import { useSelector } from "react-redux";

import NavigationState from "types/state/NavigationState";
import RootState from "types/state/RootState";

const useNavigation = (): NavigationState => {
    return useSelector((state: RootState): NavigationState => state.navigation);
};

export default useNavigation;
