import { useSelector } from "react-redux";

import EpiPage from "types/EpiPage";
import RootState from "types/state/RootState";

const usePageContent = <T extends EpiPage>(): T => {
    return useSelector((state: RootState<T>): T => state.pageContent);
};

export default usePageContent;
