import { styled } from "styled-components";

import { A } from "components/Link/Link.styled";

export const Wrapper = styled.aside`
    background-color: var(--block-blue-default);
    padding: 0.4375rem 0.4375rem 0.375rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1999;
`;

export const Link = styled(A)`
    border-bottom-color: var(--color-white-o00);
    color: var(--text-inverted);

    hover: {
        border-bottom-color: var(--text-inverted);
    }
`;
