import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import DisclaimerProps from "./DisclaimerProps";

const Disclaimer = loadable(
    (): Promise<DefaultComponent<DisclaimerProps>> =>
        import(/* webpackChunkName: "Disclaimer" */ "./Disclaimer"),
);
const DisclaimerLoader = (props: DisclaimerProps): ReactElement => (
    <Disclaimer {...props} />
);
export default DisclaimerLoader;
