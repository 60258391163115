import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProductCategoryListProps from "./ProductCategoryListProps";

const ProductCategoryList = loadable(
    (): Promise<DefaultComponent<ProductCategoryListProps>> =>
        import(
            /* webpackChunkName: "ProductCategoryList" */ "./ProductCategoryList"
        ),
);
const ProductCategoryListLoader = (
    props: ProductCategoryListProps,
): ReactElement => <ProductCategoryList {...props} />;
export default ProductCategoryListLoader;
