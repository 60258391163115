import { AxiosResponse } from "axios";
import { takeLatest, all, put, call } from "redux-saga/effects";

import {
    SUBMIT_FORM,
    SUBMIT_FORM_BEGIN,
    SUBMIT_FORM_COMPLETE,
    SUBMIT_FORM_ERROR,
    SUBMIT_FORM_SUCCESS,
} from "store/actionTypes";
import { postData, postJsonData } from "utils/fetch";

function* submitFormSaga(action: BeginSubmitFormAction): Generator {
    const { path, formData, id, postAsJson } = action.payload;
    //console.log(action.payload); // eslint-disable-line no-console
    yield put({ type: SUBMIT_FORM_BEGIN, id: id });
    try {
        const response = (
            postAsJson === true
                ? yield call(postJsonData, `${path}`, formData)
                : yield call(postData, `${path}`, formData)
        ) as AxiosResponse<any>; // eslint-disable-line @typescript-eslint/no-explicit-any

        const {
            data: { isSuccess, message, redirectUrl },
        } = response;

        // Epi standard form submission endpoint gives 200 responses with isSuccess as false
        if (isSuccess === false) {
            yield put({
                type: SUBMIT_FORM_ERROR,
                id: id,
                payload: { errorMessage: message },
            });
        } else {
            yield put({
                type: SUBMIT_FORM_SUCCESS,
                id: id,
                payload: { successMessage: message },
            });

            if (redirectUrl && window.location) {
                window.location = redirectUrl;
                return;
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        let errorMessage = error.message; // raw http failure reason
        let validationErrors: { [key: string]: string[] } | undefined =
            undefined;

        if (error.response) {
            const {
                data: { title, errors },
            } = error.response;

            if (title) {
                errorMessage = title;
            }
            if (errors) {
                validationErrors = errors;
            }
        }

        yield put({
            type: SUBMIT_FORM_ERROR,
            id: id,
            payload: {
                errorMessage: errorMessage,
                validationErrors: validationErrors,
            },
        });
    } finally {
        yield put({
            type: SUBMIT_FORM_COMPLETE,
            id: id,
        });
    }
}

function* formSagas(): Generator {
    yield all([takeLatest(SUBMIT_FORM, submitFormSaga)]);
}

export default formSagas;

export interface BeginSubmitFormAction {
    type: typeof SUBMIT_FORM;
    payload: {
        path: string;
        formData: object;
        postAsJson?: boolean;
        id: string;
    };
}
