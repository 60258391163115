import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import TextAreaProps from "./TextAreaProps";

const TextArea = loadable(
    (): Promise<DefaultComponent<TextAreaProps>> =>
        import(/* webpackChunkName: "TextArea" */ "./TextArea"),
);
const TextAreaLoader = (props: TextAreaProps): ReactElement => (
    <TextArea {...props} />
);
export default TextAreaLoader;
