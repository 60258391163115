import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProfilePageProps from "./ProfilePageProps";

const ProfilePage = loadable(
    (): Promise<DefaultComponent<ProfilePageProps>> =>
        import(/* webpackChunkName: "ProfilePage" */ "./ProfilePage"),
);
const ProfilePageLoader = (props: ProfilePageProps): ReactElement => (
    <ProfilePage {...props} />
);
export default ProfilePageLoader;
