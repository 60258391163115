import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SideBySideFullBleedProps from "./SideBySideFullBleedProps";

const SideBySideFullBleed = loadable(
    (): Promise<DefaultComponent<SideBySideFullBleedProps>> =>
        import(
            /* webpackChunkName: "SideBySideFullBleed" */ "./SideBySideFullBleed"
        ),
);
const SideBySideFullBleedLoader = (
    props: SideBySideFullBleedProps,
): ReactElement => <SideBySideFullBleed {...props} />;
export default SideBySideFullBleedLoader;
