import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ArticlePageProps from "./ArticlePageProps";

const ArticlePage = loadable(
    (): Promise<DefaultComponent<ArticlePageProps>> =>
        import(/* webpackChunkName: "ArticlePage" */ "./ArticlePage"),
);
const ArticlePageLoader = (props: ArticlePageProps): ReactElement => (
    <ArticlePage {...props} />
);
export default ArticlePageLoader;
