import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProductCategoryPageProps from "./ProductCategoryPageProps";

const ProductCategoryPage = loadable(
    (): Promise<DefaultComponent<ProductCategoryPageProps>> =>
        import(
            /* webpackChunkName: "ProductCategoryPage" */ "./ProductCategoryPage"
        ),
);
const ProductCategoryPageLoader = (
    props: ProductCategoryPageProps,
): ReactElement => <ProductCategoryPage {...props} />;
export default ProductCategoryPageLoader;
