const transferSearchParams = (
    fromUrl: URL,
    toUrl: URL,
    paramsToTransfer: string[],
): URL => {
    const fromLinkParams = fromUrl.searchParams;
    const toLinkParams = toUrl.searchParams;
    paramsToTransfer.forEach((param) => {
        const value = fromLinkParams.get(param);
        if (value) {
            toLinkParams.set(param, value);
        }
    });

    return toUrl;
};

export default transferSearchParams;
