import { styled, css } from "styled-components";
import theme from "styled-theming";

import {
    styleBodyM,
    styleLabelS,
    styleLabelM,
} from "style/components/Typography";

const textColor = theme("theme", {
    undefined: "--text-on-neutral-primary",
    default: "--text-on-neutral-primary",
    white: "--text-on-neutral-primary",
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

const borderColor = theme("theme", {
    undefined: "--line-on-neutral-default",
    default: "--line-on-neutral-default",
    white: "--line-on-neutral-default",
    lightgray: "--line-on-neutral-default",
    blue: "--line-on-blue-default",
    cyan: "--line-on-cyan-default",
});

export const responsiveTable = css`
    overflow-x: auto;
    width: 100%;
`;

export const styledTable = css`
    border: 0;
    border-collapse: collapse;
    margin: 1.75rem 0;
    width: 100%;

    caption {
        ${styleLabelM}
        color: var(${textColor});
    }

    thead {
        th,
        td {
            ${styleLabelS}
        }
    }

    td {
        ${styleBodyM}
    }

    td,
    th {
        border: none;
        border-bottom: 1px solid var(${borderColor});
        color: ${textColor};
        line-height: 1;
        padding: 1.75rem;
        text-align: left;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
`;

export const Wrapper = styled.div`
    width: 100%;

    .responsive-table {
        ${responsiveTable}
    }

    table {
        ${styledTable}
    }
`;
