import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FooterSlimProps from "./FooterSlimProps";

const FooterSlim = loadable(
    (): Promise<DefaultComponent<FooterSlimProps>> =>
        import(/* webpackChunkName: "FooterSlim" */ "./FooterSlim"),
);
const FooterLoader = (props: FooterSlimProps): ReactElement => (
    <FooterSlim {...props} />
);
export default FooterLoader;
