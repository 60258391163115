import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProfileListSectionProps from "./ProfileListSectionProps";

const ProfileListSection = loadable(
    (): Promise<DefaultComponent<ProfileListSectionProps>> =>
        import(
            /* webpackChunkName: "ProfileListSection" */ "./ProfileListSection"
        ),
);
const ProfileListSectionLoader = (
    props: ProfileListSectionProps,
): ReactElement => <ProfileListSection {...props} />;
export default ProfileListSectionLoader;
