import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import DocumentProps from "./DocumentProps";

const Document = loadable(
    (): Promise<DefaultComponent<DocumentProps>> =>
        import(/* webpackChunkName: "Document" */ "./Document"),
);
const DocumentLoader = (props: DocumentProps): ReactElement => (
    <Document {...props} />
);
export default DocumentLoader;
