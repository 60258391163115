import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import TextFieldProps from "./TextFieldProps";

const TextField = loadable(
    (): Promise<DefaultComponent<TextFieldProps>> =>
        import(/* webpackChunkName: "TextField" */ "./TextField"),
);
const TextFieldLoader = (props: TextFieldProps): ReactElement => (
    <TextField {...props} />
);
export default TextFieldLoader;
