import { composeWithDevTools } from "@redux-devtools/extension";
import {
    applyMiddleware,
    createStore,
    Middleware,
    Store,
    StoreEnhancer,
} from "redux";
import createSagaMiddleware, { Task } from "redux-saga";

import RootState from "types/state/RootState";

import { rootReducer } from "./reducers";
import rootSaga from "./sagas";

export type TStore = Store<RootState> & {
    sagaTask?: Task;
    runSagaTask?: () => void;
};

const bindMiddleware = (
    middleware: Middleware<Record<string, unknown>>[],
): StoreEnhancer => {
    const composeEnhancers = composeWithDevTools({
        trace: true,
        traceLimit: 25,
    });
    if (process.env.NODE_ENV !== "production") {
        return composeEnhancers(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export const store = (preloadedState: RootState): TStore => {
    const sagaMiddleware = createSagaMiddleware();
    const store: TStore = createStore(
        rootReducer,
        preloadedState,
        bindMiddleware([sagaMiddleware]),
    );

    if (module && module.hot) {
        module.hot.accept("./sagas");
    }

    store.sagaTask = sagaMiddleware.run(rootSaga);

    return store;
};

export default store;
