import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FrequentlyAskedQuestionListProps from "./FrequentlyAskedQuestionListProps";

const FrequentlyAskedQuestionList = loadable(
    (): Promise<DefaultComponent<FrequentlyAskedQuestionListProps>> =>
        import(
            /* webpackChunkName: "FrequentlyAskedQuestionList" */ "./FrequentlyAskedQuestionList"
        ),
);
const FrequentlyAskedQuestionListLoader = (
    props: FrequentlyAskedQuestionListProps,
): ReactElement => <FrequentlyAskedQuestionList {...props} />;
export default FrequentlyAskedQuestionListLoader;
