import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import SelectProps from "./SelectProps";

const Select = loadable(
    (): Promise<DefaultComponent<SelectProps>> =>
        import(/* webpackChunkName: "Select" */ "./SelectDefault/Select"),
);

const SelectWithSearch = loadable(
    (): Promise<DefaultComponent<SelectProps>> =>
        import(
            /* webpackChunkName: "Select" */ "./SelectWithSearch/SelectWithSearch"
        ),
);

const SelectLoader = (props: SelectProps): ReactElement => {
    const { searchable = false } = props;
    return searchable ? <SelectWithSearch {...props} /> : <Select {...props} />;
};
export default SelectLoader;
