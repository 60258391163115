import { useSelector } from "react-redux";

import CookieConsentConfig from "types/state/CookieConsentConfig";
import RootState from "types/state/RootState";

const useCookieConsent = (): CookieConsentConfig | undefined => {
    return useSelector(
        (state: RootState): CookieConsentConfig | undefined =>
            state.cookieConsent,
    );
};

export default useCookieConsent;
